.order-card{
  border-bottom:1px solid #f0f0f0;
  padding:8px 0px;
}

.full-page-card.order-card{
  border-top:1px solid #F0F0F0;
  padding-top:10px;
  padding-bottom:8px;
}

.order-card-order-type{
  font-family:Poppins;
  font-weight:900;
  font-size:18px;
}

.order-card-order-type svg {
  position:relative;
  top:5px;
  right:-8px;
}

@media only screen and (min-width: 599px){
  .order-card-order-type{
    font-size:22px;
  }
}

.order-card-time{
  font-family:Poppins;
  font-weight:500;
  font-size:14px;
  color: #8C8C8C;
}

@media only screen and (min-width: 599px){
  .order-card-time{
    font-size: 16px;
    font-weight: 600;
  }
}

.order-card-clock-container svg{
  fill: #8C8C8C;
  height:18px;
  width:18px;
  margin-left:5px;
  position:relative;
  top:3px;
}

.order-card-diner{
  font-family:Poppins;
  font-weight:600;
  font-size:16px;
}


@media only screen and (min-width: 599px){
  .order-card-diner{
    font-size:20px;
  }
}

.order-card-price{
  font-family:Poppins;
  font-weight:400px;
  font-size:16px;
  display:flex;
  flex-direction:row;
  align-items:flex-end;
  height:34px;
}


@media only screen and (min-width: 599px){
  .order-card-price{
    font-size:18px;
  }
}

.order-card-price-inner-div{
    height: 18px;
    line-height: 18px;
    position: relative;
    top: 2px;
}

.order-card-action-button{
  font-family:Poppins;
  font-weight:600;
  font-size:14px;
  border-radius:16px;
  padding:3px 10px;
  cursor:pointer;
}


@media only screen and (min-width: 599px){
  .order-card-action-button{
    font-size:16px;
  }
}

.order-card-confirm-order{
  color: #34A853;
  border: 2px solid #34A853;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
}

.active-orders-bucket .order-card-confirm-order{
  color: #0B1246 !important;
  border-color:#0B1246 !important;
}

.ready-check{
  fill: #0B1246 !important;
  height:22px;
  position:relative;
  margin-left:5px;
}

.order-card-bottom-row{
  position:relative;
  top:-12px;
  align-items:flex-end !important;
}

.order-card-tag{
  padding-left:8px;
  padding-right:8px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  font-size:12px;
  font-family:Poppins;
  font-weight:600;
  color:#262525;
  background-color: #E7E8FF;
  height:28px;
  border: 1px dashed #000CCF;
}

.order-card-tag svg{
  margin-right:5px;
  height:18px;
  border-radius:2px;
}

.order-card-tags{
  display:flex;
  flex-direction:row;
  margin-bottom:10px;
}

.modal-background{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;
  overflow: hidden;
  animation: fade-in .3s forwards ease;
  -webkit-animation: fade-in .3s forwards ease;
  background: rgba(0,0,0,0.5);
}

.full-page-card{
  position: relative;
  width: 100%;
  box-sizing: border-box;
  height: auto;
  min-height: 100%;
  max-height: 92%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 8px 48px -16px rgba(0,0,0,.4);
  z-index: 11; 
  animation: slide-up .3s forwards ease;
  -webkit-animation: slide-up .3s forwards ease;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
}



@media only screen and (min-width: 599px) and (max-width:1000px){
  .full-page-card{
    border-radius:0px;
    position:fixed;
    bottom:0px;
    min-height: calc(100% - 68px - var(--inset-top));
    max-height: calc(100% - 68px - var(--inset-top));
    height: calc(100% - 68px - var(--inset-top) );
    width:100%;
  }
}


@media only screen and (min-width: 1000px){
  .full-page-card{
    border-radius:0px;
    position:fixed;
    right:0px;
    bottom:0px;
    height: 100%; 
    width:650px;
    animation: slide-left .1s forwards ease;
    -webkit-animation: slide-left .1s forwards ease;
    -webkit-overflow-scrolling: touch;
  }

  .modal-background{
    animation: none; 
    -webkit-animation: none; 
  }

  .full-page-card .orders-content{
    padding-left:20px !important;
    padding-right:20px !important;
  }



}

.full-page-card-from-right{
  animation: slide-left 0.3s forwards ease !important;
  -webkit-animation: slide-left 0.3s forwards ease !important;
}

.full-page-card-header{
  z-index:99;
  height:68px;
  width:100%;
  background-color:#239C44;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  flex-direction:row;
  position:sticky;
  top:0px;
  border-top-right-radius:19px;
  border-top-left-radius:19px;
}


@media only screen and (min-width: 599px){
  .full-page-card-header{
    border-top-right-radius:0px;
    border-top-left-radius:0px;
    height:76px;
  }
}

.scheduled-header{
  background-color:#000CCF;
}

.gray-header{
  background-color:#F4F4F4 !important;
}

.gray-header-text{
  color:#0B1246 !important;
}

.full-page-card-header-subtext{
  font-family:Poppins;
  font-size:18px;
  font-weight:400;
  color:#FCFCFC;
}

.full-page-card-close-inner-container{
  display:flex;
  align-items:center;
  justify-content:center;
  z-index:99;
  height:68px;
  width:50px;
}

.full-page-card-header-elipses-container{
  display:flex;
  align-items:center;
  just0ify-content:center;
  height:68px;
  width:50px;
  font-size:20px;
  font-family:Poppins;
  font-weight:900;
  color:#FCFCFC;
}



.full-page-card-content-container{
  width:100%;
}

.full-page-card-content{
  background-color: #F4F4FB;
  padding-top:env(safe-area-inset-top);
}


@media only screen and (min-width: 599px){
  .full-page-card-content-container{
    display:flex;
    flex-direction:row;
  }

  .full-page-card-content{
    box-sizing:border-box !important;
    display:inline-block;
    width:calc(100% - 229px) !important;
    vertical-align:top;
    padding-top:0px !important;
  }
}

.full-page-card-sidebar{
  width:229px;
  box-sizing:border-box;
  padding-left:15px;
  padding-right:15px;
  vertical-align:top;
  background-color:#F4F4FB;
  top:76px;
  position:sticky;
  display:inline-block;
  vertical-align:top;
}

.full-page-card-innermost-container{
  padding:7px;
  box-sizing:border-box;
}

.full-page-card-close-inner-container svg{
  fill:#FCFCFC;
  height:18px;
  width:18px;
}

#modal-background-exit{
  animation: drop-out .2s forwards ease;
  -webkit-animation: drop-out .2s forwards ease;
}

#full-page-card-exit{
  animation: fade-out .2s forwards ease;
  -webkit-animation: fade-out .2s forwards ease;
}

#modal-background-exit-right{
  animation: slide-right-out .2s forwards ease;
  -webkit-animation: slide-right-out .2s forwards ease;
}

#full-page-card-exit-right{
  animation: slide-right-out .2s forwards ease;
  -webkit-animation: slide-right-out .2s forwards ease;
}

.full-page-card-header-text{
  font-family:OPTIChampionBold;
  font-weight:900;
  font-size:18px;
  color:#FCFCFC;
  padding-left: 10px;
}


@media only screen and (min-width: 599px){
  .full-page-card-header-text{
    font-size:22px;
  }
}

.full-page-card-order-info-order-type{
  font-family:OPTIChampionBold;
  font-weight:900;
  font-size:18px;
  color:#0B1246;
}

.full-page-card-order-info-time{
  font-family:Poppins;
  font-weight:500;
  font-size:18px;
  color:#D14848;
}

.full-page-card-order-placed, .full-page-card-due{
  font-family:Poppins;
  font-weight:500;
  font-size:14px;
  color: #757575;
}

.full-page-card-order-info-container{
  margin-top:15px;
}


@media only screen and (max-width: 599px){
  .full-page-card-order-info-container{
    margin-top:0px;
    padding-top:15px;
  }
}

.full-page-card-diner-container{
  margin-top:25px;
  background-color:#F4F4FB;
  padding-top:12px;
  padding-bottom:32px;
  width:100%;
  padding-left:20px;
  padding-right:20px;
  position:relative;
  left:-20px;
}

@media only screen and (min-width: 599px){
  .full-page-card-diner-container{
    margin-top:0px;
    background-color:#FCFCFC;
  }
}

.full-page-card-diner-inner-container{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
}

.full-page-card-diner-name{
  font-family:Poppins;
  font-weight:700;
  font-size:18px;
  color:#262525;
}

@media only screen and (min-width: 599px){
  .full-page-card-diner-name{
    font-size:20px;
  }
}

.full-page-card-diner-phone{
  font-family:Poppins;
  font-weight:600;
  font-size:16px;
  color:#262525;
  text-decoration:underline;
}


@media only screen and (min-width: 599px){
  .full-page-card-diner-phone{
    font-size:18px;
  }
}

.full-page-card-diner-left{
  display:flex;
  flex-direction:column;
  align-items:flex-start;
}

.full-page-card-printer-wrapper{
  background-color:#000CCF;
  border-radius:16px;
  padding:7px 10px;
  display:flex;
  align-items:center;
  justify-content:center;
}

.full-page-card-printer-wrapper svg{
  width:18px;
  height:18px;
  fill:#FCFCFC;
}


.full-page-card-footer-main-button{
  border-radius:40px;
  width:calc(100% - 40px - 20px);
  display:flex;
  align-items:center;
  justify-content:center;
  background-color:#000CCF;
  color:#FCFCFC;
  font-family:Poppins;
  font-size:16px;
  font-weight:600;
  height:40px;
  cursor:pointer;
  position:fixed;
  bottom:0px;
  z-index:99;
  position:absolute;
}

.full-page-card-footer-button-wrapper{
  position:sticky;
  bottom:0px;
  left:0px;
}

.full-page-card-footer-round-button{
  border-radius:40px;
  width:40px;
  height:40px;
  display:flex;
  align-items:center;
  justify-content:center;
  background-color:#000CCF;
  color:#FCFCFC;
  font-family:OPTIChampionBold;
  font-size:16px;
  font-weight:900;
  height:40px;
  cursor:pointer;
  bottom:0px;
  right:10px;
  z-index:99;
  position:absolute;
}


.full-page-card-footer-round-button-close{
  background-color:#D14848;
  z-index:99;
}


.full-page-card-footer-round-button-close svg{
  fill:#FCFCFC;
  height:15px;
  width:15px;
}

.full-page-card-footer-round-button-wrapper{
  position:sticky;
  bottom:0px;
  left:0px;
  z-index:99;
}

.full-page-card-extra-options-wrapper{
  position:sticky;
  bottom:0px;
  left:0px;
  z-index:99;
}

.full-page-card-extra-options{
  border-radius:20px;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  bottom:50px;
  right:10px;
  z-index:99;
  position:absolute;
  overflow:hidden;
}

.full-page-card-extra-option{
  width:242px;
  height:46px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  font-family:Poppins;
  font-weight:600;
  font-size:16px;
  background-color: #FCFCFC;
  border-bottom: 1px solid #DFDFDF;
  color: #262525;
}

.button-gray{
  background-color:#DFDFDF;
  color:#8C8C8C;
}


@keyframes slide-up {
  0%{
    transform: translateY(100vh);
  }
  100%{
    transform: translateY(0vh);
  }
}

@-webkit-keyframes slide-up {
  0%{
    transform: translateY(100vh);
  }
  100%{
    transform: translateY(0vh);
  }
}

@keyframes slide-left {
  0%{
    transform: translateX(100vw);
  }
  100%{
    transform: translateX(0vw);
  }
}

@-webkit-keyframes slide-left {
  0%{
    transform: translateX(100vw);
  }
  100%{
    transform: translateX(0wh);
  }
}


@keyframes slide-right-out {
  0%{
    transform: translateX(0vw);
  }
  100%{
    transform: translateX(100vw);
  }
}

@-webkit-keyframes slide-right-out {
  0%{
    transform: translateX(0vw);
  }
  100%{
    transform: translateX(100wh);
  }
}

@keyframes drop-out {
  0%{
    transform: translateY(0vh);
  }
  100%{
    transform: translateY(100vh);
  }
}

@-webkit-keyframes drop-out {
  0%{
    transform: translateY(0vh);
  }
  100%{
    transform: translateY(100vh);
  }
}

@keyframes fade-out {
  0%{
    background: rgba(0,0,0,.5);
  }
  100%{
    background: rgba(0,0,0,0);
  }
}

@keyframes fade-in {
  0%{
    background: rgba(0,0,0,0);
  }
  100%{
    background: rgba(0,0,0,0.5);
  }
}

.expanded-circle-modal-background{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 500vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  overflow: hidden;
  background: rgba(0,0,0,0.5);
}

.full-page-card-disabled{
  height:100% !important;
  overflow: hidden !important;
}

.cancel-red{
  color:#D14848;
}

.full-page-card-special-instructions-wrapper{
  width:100%;
  padding-left:20px;
  padding-right:20px;
  position:relative;
  left:-20px;
  background-color:#FCFCFC;
  padding-top:15px;
  padding-bottom:15px;
}

.full-page-card-special-instructions{
  background-color: #FFFAEB;
  color: #0B1246;
  border: 2px solid #E1B310;
  border-radius:4px;
  box-sizing:border-box;
  width:100%;
  padding-top:5px;
  padding-bottom:5px;
  padding-left:9px;
  padding-right:9px;
}

.full-page-card-special-instructions-text{
  background-color: #FFFAEB;
  box-sizing:border-box;
  width:100%;
  font-family:Poppins;
  font-size:14px;
  font-weight:500;
}


@media only screen and (min-width: 599px){
  .full-page-card-special-instructions-text{
    font-size:16px;
  }
}

.full-page-card-special-instructions-header{
  font-family:Poppins;
  font-weight:700;
  font-size:16px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-start;
  padding-bottom:7px;
}

.full-page-card-special-instructions-header-text{
  position:relative;
  top:1px;
}


@media only screen and (min-width: 599px){
  .full-page-card-special-instructions-header-text{
    font-size:16px;
  }
}

.full-page-card-special-instructions-warning{
  height:20px;
  width:20px;
  margin-right:5px;
}

.full-page-card-diner-address-container{
  color: #262525;
  font-family:Poppins;
  font-weight:600;
  font-size:16px;
}

.cancel-reasons-container{
  margin-top: 25px;
}

.cancel-reason-header-text{
  font-family:Poppins;
  font-weight:600;
  font-size:16px;
}

.cancel-reason-container{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-start;
  padding:7px 0px;
}

.cancel-reason-reason{
  font-family:Poppins;
  font-size:16px;
  font-weight:400;
}

.cancel-reasons-container svg{
  width:18px;
  height:18px;
  margin-right:10px;
}

.card-button{
  width:100%;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  font-size:16px;
  font-family:Poppins;
  font-weight:600;
  border-radius:40px;
  box-sizing:border-box;
  min-height:40px;
  cursor:pointer;
}

.keep-this-order{
  color: #000CCF;
  border: 1px solid #000CCF;
  margin-top:15px;
  margin-bottom:20px;
}

.decline-disabled{
  background-color: #DFDFDF;
  pointer-events:none;
  color:#8C8C8C;;
}

.decline-this-order{
  background-color:#EA5151;
  color:#FCFCFC;
}

.full-page-card-header-back-chevron{
  height:18px;
  width:18px;
  position:relative;
  left:20px;
}

.order-card-scheduled-time-of-day{
  font-size:22px;
  font-family:Poppins;
  font-weight:700;
}

@media only screen and (max-width: 599px){
  .order-card-scheduled-time-of-day{
    font-size:18px;
  }
}

.full-page-card-header-scheduled-container{
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
}

.order-card-active-at{
  font-family:Poppins;
  font-weight:500;
  font-size:16px;
  color:#8C8C8C;
}

.order-card-history-status{
  font-size:14px;
  color:#239C44;
  font-family:Poppins;
  font-weight:600;
}

.button-with-spinnner-svg-container{
  display:flex;
  align-items:center;
  justify-content:center;
  margin-left:7px;
}

.order-card-readied-at{
  font-family:Poppins;
  font-weight:500;
  font-size:16px;
  color:#8C8C8C;
}

.order-card-scheduled-unconfirmed{
  padding: 12px;
  width: 100%;
  border: 1px dashed #000CCF;
  left: -10px;
  position: relative;
  font-size:18px;
  background-color: #F4F4FB;
}

.order-modal-more-options-container{
  position:absolute;
  z-index:9999;
  background-color:#fcfcfc;
  right:30px;
  top:60px;
  border-top-left-radius:20px;
  border-bottom-left-radius:20px;
  border-bottom-right-radius:20px;
}

.order-modal-more-options-option{
  font-family:Poppins;
  font-size:18px;
  color:#0B1246;
  font-weight:600;
  display:flex;
  align-items:center;
  justify-content:center;
  height:50px;
  width:240px;
}

@media only screen and (max-width: 599px){
  .order-modal-more-options-option{
    font-size:16px;
  }
}

.bottom-grey-border{
  border-bottom: 1px solid #DFDFDF;
}

.more-options-close{
  border-radius:50px;
  background-color:white;
  display:flex;
  align-items:center;
  justify-content:center;
  width:35px;
  height:35px;
  z-index:9999;
  position:relative;
}

.more-options-close-inner{
  position:absolute;
  z-index:9999;
}


.more-options-close svg{
  height:18px;
  width:18px;
  fill:#D14848;
}

.full-page-card-mobile-bottom-button-container{
  width:100%;
  position:sticky;
  bottom:30px;
  display:flex;
  align-items:center;
  justify-content:center;
  z-index:999;
}

.full-page-card-bottom-inner-container{
  bottom:0px;
  position:absolute;
  width:calc(100% - 100px);
}

.full-page-card-bottom-inner-container-adjust{
  bottom:10px;
  position:absolute;
  min-width:calc(100% - 100px);
  max-width:calc(100% - 10px);
}

.full-page-card-bottom-inner-container-adjust div{
  padding:0px 20px;
}


.full-page-card-mobile-bottom-button{
  display:flex;
  align-items:center;
  justify-content:center;
  background-color:#000CCF;
  color:#FCFCFC;
  font-size:16px;
  font-weight:600;
  font-family:Poppins;
  height:40px;
  min-width:80%;
  border-radius:40px;
}

.full-page-card-expanded-options{
  background-color:#FCFCFC;
  border-radius:20px;
  margin-bottom:20px;
  min-width:80%;
  box-shadow: 0px 4px 4px rgba(52, 64, 255, 0.25), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12);
  position:relative;
  top:-1px;
}

.full-page-card-expanded-option:active{
  background-color:#E7E8FF;  
}

.expanded-option-selected{
  background-color:#E7E8FF;  
}

.full-page-card-expanded-option{
  width:100%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color:262525;
  display:flex;
  align-items:center;
  justify-content:center;
  height:46px;
  box-sizing:border-box;
}

.expanded-option-border{
  border-bottom: 1px solid #DFDFDF;
}

.expanded-buttons-overlay{
  width:1000px;
  height:10000px;
  background-color:black;
  opacity:0.5;
  z-index:99;
  position:absolute;
  top:0px;
}

.full-page-card-mobile-bottom-button-open{
  background-color:#E7E8FF;
  color:#000CCF;
}

.in-person-blurb{
  padding-top: 40px;
  font-size: 18px;
  font-weight: 600;
}
