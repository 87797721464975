.orders-home-pills{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-start;
  padding-left:20px;
  padding-right:20px;
  padding-top:15px;
  background-color:white;
  position:sticky;
}

.orders-pill{
  height:40px;
  border-radius:10px;
  display:flex;
  align-items:center;
  justify-content:center;
  font-size:16px;
  font-weight:700;
  font-family:Poppins;
  padding:0px 15px;
  border: 2px solid #8C8C8C;
  color: #8C8C8C;
  white-space:nowrap;
}

.orders-pill-selected{
  color:#FCFCFC;
  background-color:#000CCF;
  border:2px solid #000CCF;
}

.orders-pill-kitchen{
  margin-right:14px;
}

.orders-pill-scheduled{
  margin-right:14px;
}

.orders-pill svg{
  fill: #8C8C8C;
  height:22px;
  position:relative;
  top:3px;
  left:2px;
}

@media only screen and (min-width: 599px){
  .orders-pill{
    font-size:20px;
  }
}


.orders-home-current-page{
  font-family:OPTIChampionBold;
  font-weight:900;
  font-size:18px;
  color:#0B1246;
  margin-top:17px;
  margin-bottom:10px;
}


@media only screen and (min-width: 599px){
  .orders-home-current-page{
    font-size:24px;
  }
}


@media only screen and (min-width: 1000px){
  .orders-home-current-page{
    width:100%;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
  }

  .orders-home-current-page .search-bar-container{
    width:350px !important;
    margin-top: 0px !important;
    height: 40px !important;
    background-color:white !important;
  }

  .orders-home-current-page .search-bar-input{
    background-color:white !important;
    height: 40px !important;
    font-size:16px !important;
  }

  .orders-home-current-page .search-bar-icon{
    top:12px !important;
  }

  .desktop-orders-content{
    height:calc(100vh - 76px);
    overflow-y:auto;
  }
}

.orders-bucket-header{
  background-color:#f0f0f0;
  height: 40px;
  position:relative;
  padding-left:20px;
  padding-right:20px;
  width:100%;
  left:-20px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  color: #262525;
}

.orders-bucket-warning .orders-bucket-header{
  color:#FCFCFC;
  background-color:#D14848;
}

.orders-bucket-warning .orders-bucket-header svg{
  fill:#FCFCFC;
}

.orders-bucket-warning .order-card-order-type{
  color: #D14848;
}

.orders-bucket-warning .order-card-time{
  color: #D14848;
}

.orders-bucket-warning .order-card-clock-container svg{
  fill: #D14848;
}

.orders-bucket-header-left{
  font-size:16px;
  font-family:Poppins;
  font-weight:600;
}



.orders-bucket-header-left svg{
  height: 18px;
  position: relative;
  top: 3px;
  margin-right: 10px;
}


.orders-bucket-header-right{
  font-size:16px;
  font-family:Poppins;
  font-weight:700;
}


@media only screen and (min-width: 599px){
  .orders-bucket-header-left{
    font-size:20px;
  }

  .orders-bucket-header-left svg{
    height:22px;
    width:22px;
  }

  .orders-bucket-header-right{
    font-size:20px;
  }
}

.orders-bucket-header-today{
  color:#FCFCFC;
  background-color:#000CCF;
}

.orders-bucket-header-today svg{
  fill:#FCFCFC;
}

.no-orders-container{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:space-between;
  margin-top:30px;
}

.no-orders-top-text{
  color: #262525;
  font-family: OPTIChampionBold;
  font-size:18px;
  font-weight:900;
  margin-bottom:20px;
  margin-top:20px;
  text-align:center;
}

.no-orders-bottom-text{
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size:16px;
  color: #ACACAC;
  text-align:center;
}

.no-orders-img{
  height:400px;
  margin-top:30px;
  margin-bottom:25px;
}

.no-orders-img-bottle{
  width:60%;
  max-height:30%;
  margin-top:70px;
  margin-bottom:70px;
}

.back-to-kitchen{
  background-color:#000CCF;
  color:#FCFCFC;
  display:flex;
  align-items:center;
  justify-content:center;
  font-size:16px;
  font-family:Poppins;
  font-weight:600;
  margin-top:30px;
  border-radius:40px;
  height:45px;
  padding:0px 15px;
  
}

.search-bar-container{
  width:100%;
  padding:5px;
}

.search-bar-input{
  width:100%;
  height:30px;
  font-size:18px;
  font-family:Poppins;
  font-weight:500;
}
