.error-boundary-outer-container{
  width:100vw;
  height:100vh;
  display:flex;
  align-items:center;
  justify-content:center;
}

.error-boundary-container{
  display:flex;
  flex-direction:column;
  align-items:center;
  width:340px;
}

.error-boundary-warning{
  width:100px;
  height:100px;
  fill:#EA5151;
}

.error-boundary-something-went-wrong{
  color:#0B1246;
  font-family:Poppins;
  font-weight:700;
  font-size:18px;
  margin-top:30px;
  margin-bottom:30px;
}

.error-boundary-back-to-kitchen-button{
  background-color:#EA5151;
  color:#FCFCFC;
  display:flex;
  align-items:center;
  justify-content:center;
  height:40px;
  width:100%;
  font-family:Poppins;
  font-weight:600;
  font-size:16px;
  border-radius:40px;
}
