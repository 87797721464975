.create-charge-title{
  color:#0B1246;
  font-size:24px;
  font-weight:900;
  text-align:center;
  margin-top:15px;
}

.create-charge-subtitle{
  color:#0B1246;
  font-size:24px;
  font-weight:600;
}

.create-charge-input{
  font-family:Poppins;
  font-size:50px;
  border-radius:3px;
  width:200px;
  text-align:center;
  border-bottom: 1px solid gray;
  border-top:0px solid black;
  border-left:0px solid black;
  border-right:0px solid black;
  color:#262525;
}

.create-charge-dollar-sign{
  font-size:50px;
  position:relative;
  z-index:99;
  background-color:white;
}


.create-charge-input:focus{
  outline: none;
}

.create-charge-collect-payment-button{
  margin-left:20px;
  margin-right:20px;
  height:40px;
  border-radius:40px;
  padding-left:10px;
  padding-right:10px;
  display:flex;
  align-items:center;
  justify-content:center;
  background-color:#3440FF;
  color:white;
  font-size:18px;
  font-weight:600;
}

.terminal-waiting-for-connection{
  display:flex;
  align-items:center;
  justify-content:center;
  font-size:25px;
}

.terminal-waiting-for-connection svg{
  height:25px;
  width:25px;
  fill:black;
  margin-right:15px;
}


.create-charge-amount-container{
  z-index:99;
  background-color:white;
  position:relative;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  margin-bottom:70px;
}

.create-charge-first-page-content{
  height:calc(100vh - 300px);
  display:flex;
  justify-content:center;
  width:100%;
  padding-top:30px;
}

.create-charge-m2-image-container{
  width:100%;
  height:55%;
  box-sizing:border-box;
  padding-top:40px;
  padding-left:40px;
  padding-right:40px;
}

.create-charge-m2-image{
  width:100%;
}


.create-charge-pay-container{
  height:355px;
  background: #F4F4F4;
  position:fixed;
  bottom:0px;
  width:100%;
  border-top-left-radius:40px;
  border-top-right-radius:40px;
}


.create-charge-pay-page{
  top:0px;
  z-index:0;
}

.create-charge-paid-page{
  top:0px;
  z-index:0;
  background-color:#239C44;
  height:calc(100vh - 150px);
}

.create-charge-error-page{
  top:0px;
  z-index:0;
  background-color:#D14848;
  height:calc(100vh - 150px);
}

.create-page-top-portion-final{
  box-sizing:border-box;
  border-top:5px solid white;
  background-color:#239C44;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  height:calc(100vh - 355px - 68px);
}

.create-page-top-portion-final-error{
  box-sizing:border-box;
  border-top:5px solid white;
  display:flex;
  background-color:#D14848;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  height:calc(100vh - 355px - 68px);
}

.create-page-top-portion-final-error svg{
  fill:white;
  width:200px;
  height:200px;
}



.create-charge-error-message{
  text-align:center;
  font-size:16px;
  font-weight:400;
  color:#262525;
  margin-top:20px;
  margin-bottom:30px;
}

.create-charge-pay-top-row{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  padding-left:30px;
  padding-right:30px;
  padding-top:20px;
}

.create-charge-pay-amount{
  font-size:24px;
  font-family:Poppins;
  font-weight:600;
  color:#262525;
}

.create-charge-pay-cancel{
  font-size:20px;
  font-weight:400;
  color:#D14848;
  font-family:Poppins;
}

.create-charge-tap-or-swipe{
  width:100%;
  text-align:center;
  margin-top:20px;
  font-size:24px;
  font-weight:400;
  font-family:Poppins;
  color:#0B1246;
}

.create-charge-tap-or-swipe-loading-container{
  width:100%;
  text-align:center;
}

.create-charge-tap-or-swipe-loading-container {
  margin-top:25px;
}

.create-charge-tap-or-swipe-loading-container img{
  height:60px;
}

.create-charge-final-page-text{
  color:#FCFCFC;
  font-size:40px;
  font-weight:400;
  font-family:Poppins;
}

.create-charge-paid-top-row{
  display:flex;
  align-items:center;
  justify-content:space-between;
  padding-left: 50px;
  padding-right: 50px;
  font-size:20px;
  font-weight:400;
  font-family:Poppins;
}


.create-charge-paid-top-row svg{
  height:60px;
  width:60px;
}

.create-charge-paid-toppest-row{
  padding-top: 30px;
  margin-bottom:30px;
  display:flex;
  align-items:center;
  justify-content:space-between;
  padding-left:50px;
  padding-right:50px;
}

.create-charge-payment-successful{
  display:flex;
  align-items:center;
  justify-content:center;
  font-size:20px;
  font-weight:600;
  color:#239C44;
}

.create-charge-payment-successful svg{
  margin-right:10px;
  height:25px;
  width:25px;
  fill:#239C44;
}

.create-charge-payment-successful *{
  fill:#239C44;
}


.create-charge-payment-error{
  display:flex;
  align-items:center;
  justify-content:center;
  font-size:20px;
  font-weight:600;
  color:#D14848;

}

.create-charge-payment-error svg {
  margin-right:10px;
  height:25px;
  width:25px;
  fill:#D14848;
}

.create-charge-payment-error * {
  fill:#D14848;
}

.create-charge-manual-card{
  display:flex;
  align-items:center;
  justify-content:center;
  font-size:16px;
  font-weight:600;
  font-family:Poppins;
  color:#3440FF;
}

.create-charge-manual-card svg{
  fill:#3440FF;
  height:18px;
  width:25px;
  margin-right:5px;
}

.paid-bottom-button-row{
  display:flex;
  flex-direction:row;
  align-items:center;
  width:100%;
  box-sizing:border-box;
  justify-content:space-between;
  padding-left:50px;
  padding-right:50px;
  margin-top:20px;
}

.paid-done-button{
  width:45%;
  border-radius: 40px;
  font-family:Poppins;
  font-weight:600;
  font-size:16px;
  height:40px;
  display:flex;
  align-items:center;
  justify-content:center;
  background: #3440FF;
/* 6dp Blue */
  box-shadow: 0px 4px 4px rgba(52, 64, 255, 0.25), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12);
  color:#FCFCFC;
}

.paid-print-receipt{
  width:45%;
  /* Background/Blue */
  background: #F4F4FB;
  border: 1px solid #3440FF;
  box-sizing: border-box;
  border-radius: 40px;
  color:#000CCF;
  font-family:Poppins;
  font-weight:600;
  font-size:16px;
  height:40px;
  display:flex;
  align-items:center;
  justify-content:center;
}

.paid-print-receipt svg{
  fill:#000CCF;
  height:16px;
  width:16px;
  margin-right:5px;
}

.create-charge-last4{
  font-size:16px;
  font-weight:400;
  color:#262525;
}

.create-charge-paid-name-amount-container{
  color:#262525;
  font-size:20px;
  font-weight:600;
}

.collect-title{
  color:#0B1246;
  font-size:24px;
  font-family:Poppins;
  font-weight:900;
}

.collect-subtitle{
  color:#0B1246;
  font-size:24px;
  font-family:Poppins;
  font-weight:600;
}

.collect-title-container{
  padding:20px;
  display:flex;
  justify-content:space-between;
  color:#0B1246;
}

.collect-title-total{
  font-size:16px;
  font-weight:600;
}

.collect-title-total-amount{
  font-size:24px;
  font-weight:400;
}

.submit-payment-button{
  height:40px;
  width:100%;
  border-radius:40px;
  padding-left:10px;
  padding-right:10px;
  display:flex;
  align-items:center;
  justify-content:center;
  background-color:#3440FF;
  color:white;
  font-size:18px;
  font-weight:600;
  margin-top:30px;
  border: none;
  outline: none;
}


.cancel-payment-button{
  border: none;
  outline: none;
  height:40px;
  width:30%;
  border-radius:40px;
  padding-left:10px;
  padding-right:10px;
  display:flex;
  align-items:center;
  justify-content:center;
  background-color:#D14848;
  color:white;
  font-size:18px;
  font-weight:600;
  margin-top:30px;
}

.create-payment-manual-error{
  font-size: 24px;
  color:#D14848;
  font-weight: 700;
  margin-bottom: 20px;
  display:flex;
  justify-content:center;
  align-items:center;
}

.create-payment-manual-error svg{
  fill:#D14848;
  height:32px;
  width:32px;
  margin-right:7px;
}

@media only screen and (max-height: 800px){
  ..create-charge-paid-toppest-row{
    margin-bottom:10px;
  }

  .create-charge-payment-successful{
    margin-left:30px;
    font-size:12px;
  }

  .create-charge-payment-successful svg{
    height:12px;
    width:12px;
  }
  
  .create-charge-pay-container{
    height:290px;
  }
  .create-charge-m2-image-container{
    height:30%;
  }
  .create-page-top-portion-final{
    height:calc(100vh - 250px - 56px);
  }

  .create-charge-paid-name-amount-container{
    font-size:12px;
  }

  .collect-title{
    font-size:18px;
  }

  .collect-subtitle{
    font-size:18px;
  }

  .create-charge-paid-top-row svg{
    width:30px;
    height:30px;
  }
}

.manual-input-loading-modal{
  width:100vw;
  height:100vh;
  position:absolute;
  top:0px;
  left:0px;
  background-color:white;
  z-index:99;
  display:flex;
  align-items:center;
  justify-content:center;
  opacity:0.5;
}
