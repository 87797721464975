.a-button {
  border: none;
  cursor: pointer;
}

.button--blue {
  background-color: var(--p-blue);
  border: 2px solid var(--p-blue);
}

.button--pink {
  background-color: var(--p-pink);
  border: 2px solid var(--p-pink);
}

.button--white {
  background-color: white;
  border: 2px solid white;
}

.button--transparent {
  background: transparent;
}

.button--no-radius {
  border-radius: 0px;
}

.button--primary {
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  font-weight: 900;
  font-size: 1.25rem;
  & > div {
    color: white;
    -webkit-text-fill-color: white;
  }
  color: white;
  -webkit-text-fill-color: white;

  &.button--blue:not(.button--transparent):not(.button--disabled):hover {
    background-color: transparent;
    color: var(--p-blue);
    -webkit-text-fill-color: var(--p-blue);
    box-shadow: none;
    & > div {
      color: var(--p-blue);
      -webkit-text-fill-color: var(--p-blue);
    }
  }

  &.button--pink:not(.button--transparent):not(.button--disabled):hover {
    background-color: transparent;
    color: var(--p-pink);
    -webkit-text-fill-color: var(--p-pink);
    box-shadow: none;

    & > div {
      color: var(--p-pink);
      -webkit-text-fill-color: var(--p-pink);
    }
  }

  & .button--white:not(.button--transparent):not(.button--disabled):hover {
    color: var(--p-blue);
    -webkit-text-fill-color: var(--p-blue);
    background-color: white;
  }
}

.button--primary-inverted {
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  font-weight: 900;
  background-color: transparent;
  font-size: 1.25rem;
  color: var(--p-blue);
  -webkit-text-fill-color: var(--p-blue);

  &.button--blue:not(.button--transparent):not(.button--disabled):hover {
    background-color: var(--p-blue);
    color: white;
    -webkit-text-fill-color: white;
    box-shadow: none;
  }

  &.button--white {
    color: white;
    -webkit-text-fill-color: white;
    &:not(.button--transparent):not(.button--disabled):hover {
      background-color: white;
      color: var(--p-blue);
      -webkit-text-fill-color: var(--p-blue);
    }
  }
}

.button--secondary {
  border: none;
  background-color: transparent;
  font-weight: 900;
  font-size: 1.25rem;
  color: var(--p-blue);
  -webkit-text-fill-color: var(--p-blue);
  &.button--blue:hover {
    border-bottom: 2px solid var(--p-blue);
  }

  &.button--pink {
    color: var(--p-pink);
    -webkit-text-fill-color: var(--p-pink);
    &:hover {
      border-bottom: 2px solid var(--p-pink);
    }
  }
}

//leave disbled as the last class
.button--disabled {
  cursor: not-allowed;
  background: var(--p-grey);
  box-shadow: none;
  color: white;
  -webkit-text-fill-color: white;
  border: none;
}
