.input--default {
  background: initial;
  width: 100%;
  transition: initial;

  margin: initial;
  padding: initial;
  font-size: 16px;
  max-width: initial;
  border-radius: 8px;
  padding: 0.5rem;
  border: 1px solid #eee;
}

.input--default:focus {
  border-bottom: initial;
}

.input--error {
  border: 1px solid #ff0000;
  background-color: #ffcccc;
}

.input__container {
  position: relative;
}

.input__container--error::before {
  content: attr(data-error-message);
  color: red;
  position: absolute;
  top: -20px;
}
