.desktop-wrapper{
  width:100%;
  display:flex;
  flex-direction:row;
  align-items:flex-start;
  justify-content:space-between;
}


.desktop-sidebar{
  width:236px;
  min-height:100vh;
  height:100%;
  background-color:#F4F4F4;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:flex-start;
  padding:20px 20px;
  box-sizing:border-box;
  position: sticky;
  top: 0px;
}

.desktop-sidebar-rest-name{
  font-weight:700;
  font-size:18px;
  color:#0B1246;
  margin-top:20px;
}

.desktop-sidebar-rest-secondary-name{
  margin-top:3px;
  font-size:16;
  color:#0B1246;
}


.desktop-sidebar-hr{
  width:100%;
  height:1px;
  background-color:#AAABAF;
  margin-top:13px;
  margin-bottom:13px;
}

.desktop-sidebar-section-title{
  font-size:16px;
  font-weight:400;
  color:#757575;
}

.desktop-sidebar-link{
  font-size:18px;
  font-weight:500;
  color:#262525;
  margin-top:7px;
  cursor:pointer;
}

.desktop-sidebar-link:hover{
  text-decoration:underline;
}

.desktop-menu-container{
  width:calc(100% - 236px - 236px);
  padding-top:50px;
}

.desktop-content-with-crumbs{
  padding-top:50px;
  width:100%;
}

.desktop-side-bar-link-header{
  font-family:Poppins;
  font-weight:400;
  font-size:16px;
  color:#757575;
}

.desktop-sidebar-link-selected{
  color:#0B1246;
  font-weight:600;
}
