.logo-wrapper {
  display: flex;

  flex-direction: column;
  //   flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 20%;
  margin-top: 1rem;
  // only apply styles to children in the middle
  &:not(:first-child):not(:last-child) {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

.logos__container {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  margin-right: 1rem;
  flex-wrap: wrap;
  // justify-content: space-between;
}

.logo__used-in {
  min-height: 30px;
}

.logo__image {
  max-height: 200px;
  max-width: 200px;
}

.logo__image-container {
  padding: 0.5rem;
  margin-top: 0.5rem;
  min-height: 200px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.logo__image-container--with-image {
  background-color: #f2f2f2;
}

.logo__upload-container {
  margin-top: 1rem;
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed var(--p-blue);
}

.logo__upload-container:hover,
.logo__upload-container--dragging {
  cursor: pointer;
  background: #f3f1fb;
}

.logo__edit-modal-actions {
  width: 100%;
  // margin: 3rem auto 0 auto;
  padding-top: 1.5rem;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo__image-uploaded {
  margin-top: 1rem;
  min-height: 200px;
  background-color: #f2f2f2;
  padding: 1rem;

  & > img {
    max-height: 200px;
  }
}

@media only screen and (max-width: 800px) {
  .logos__container {
    flex-direction: column;
  }

  .logo-wrapper {
    &:not(:first-child) {
      margin-top: 2rem;
    }
  }
}
