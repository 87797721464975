.basic-info__container {
  display: flex;
  flex-direction: column;
}

.basic-info__row-field {
  display: flex;
  flex: 1 1 0;
  margin-right: 1rem;
}

.basic-info__row-value {
  display: flex;
  //   flex: 1 0 40%;
  flex: 4 0;
}

.basic-info__row-value--active {
  display: flex;
  flex: 4 0;
}
