.solana-pay-qr-code-container {
  display:flex;
  align-items:center;
  justify-content:center;
}

.coin-selector{
  box-sizing: border-box;
  border-radius: 10px;
  font-weight: 400;
  font-size: 24px;
  display:flex;
  align-items:center;
  justify-content:space-between;
  margin-top:30px;
  cursor:pointer;
  height:52px;
}

.coin-selector-selected{
  background: #FCFCFC;
  border: 2px solid #919397;
  color:black;
}

.coin-selector-selected svg{
  fill:#919397;
}


.coin-selector-unselected{
  color: #000CCF;
  background: #E7E8FF;
  border: 2px solid #000CCF;
}

.crypto-dropdown-container{
  position:absolute;
  z-index:999;
  background-color:white;
  width:calc(100% -  40px);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15)) drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 18px rgba(0, 0, 0, 0.12));
  border-radius:10px;
}

.crypto-option{
  display:flex;
  align-items:center;
  width:100%;
  height:60px;
  box-sizing:border-box;
  padding-left:15px;
  font-size:24px;
  font-weight:400;
  cursor:pointer;
}

.crypto-dropdown-border{
  border-bottom:1px solid #F0F0F0;
}

.crypto-option svg{
}

.cryptop-option-svg-container{
  width:35px;
  height:100%;
  display:flex;
  align-items:center;
}

.crypto-chevron-container{
  width:40px;
  height:100%;
  display:flex;
  align-items:center;
}

.crypto-selector-coin-container{
  display:flex;
  align-items:center;
  height:100%;
}

.crypto-selector-coin-container-svg{
  display:flex;
  align-items:center;
  justify-content:center;
  height:100%;
  width:45px;
}

.crypto-selector-coin-container-text{
  height:100%;
  display:flex;
  align-items:center;
}

.collect-payment-disabled{
  background: #DFDFDF;
  color: #8C8C8C;
}

.crypto-please-select{
  display:flex;
  align-items:center;
  justify-content:center;
  margin-top:30px;
  margin-bottom:50px;
  font-size: 40px;
  line-height: 60px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #AAABAF;
}

.crypto-wait-big-svg-container svg{
  width:250px;
  height:250px;
}

.crypto-wait-big-svg-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 490px);
}
