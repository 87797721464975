.toast-container {
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  z-index: 13;
}

@media only screen and (max-width: 800px) {
  .toast-container {
    margin-top: 3rem;
  }
}

.a-toast {
  border-radius: 8px;
  padding: 1rem 1.5rem;
  margin: 1rem;
  color: black;
  font-weight: 600;
  background: white;
  box-shadow: 0px 4px 6px #0000001f, 0px 1px 12px 4px #0000000a;
  font-size: 1rem;
  z-index: 13;
}

.a-toast--success {
  border-left: 9px solid #559040;
}

.a-toast--error {
  border-left: 9px solid red;
}
