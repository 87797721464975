.orders-header-tabs{
  display:flex;
  flex-direction:row;
  align-items:center;
}

.orders-header-tab{
  flex-basis:100%;
  background-color:#f4f4f4;
  height:60px;
  display:flex;
  align-items:center;
  justify-content:center;
  font-family:Poppins;
  font-weight:600;
  font-size:22px;
  color:#595959;
  box-sizing:border-box;
  border-bottom: 3px solid #f4f4f4;
}

.orders-header-tab-four{
  font-size:18px;
}

@media only screen and (max-width: 599px){
  .orders-header-tab{
    font-size:16px;
  }

  .orders-header-tab-four{
    font-size:13px !important;
  }
}


.orders-header-tab-selected{
  color:var(--captain-black) !important;
  font-weight:900 !important;
  border-color:var(--captain-black) !important;
}

.orders-header-tab svg{
  fill:#595959;
  position: relative;
  top: 5px;
  left: 6px;
}


@media only screen and (min-width:600px){
  .orders-header-tab svg{
    top:0px;
    left:8px;
  }
}


@media only screen and (max-width: 599px){
  .orders-header-tab svg{
    fill:#595959;
    position: relative;
    left:8px;
    top:0px;
    height:18px;
    width:18px;
  }

}

.orders-header-tab-selected svg{
  fill:var(--captain-black) !important;
}
