.Background {
  position: fixed;
  top: 0;
  left: 270px;
  width: calc(100% - 270px);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.Modal {
  position: fixed;
  width: calc(100% - 32px);
  min-height: 100px;
  max-height: 90%;
  max-width: 600px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 8px 48px -16px rgba(0, 0, 0, 0.4);
  z-index: 11;
  animation: slide-up 0.5s forwards ease;
  -webkit-animation: slide-up 0.5s forwards ease;
  overflow-x: hidden;
  overflow-y: scroll;
}

.ModalLarge {
  position: fixed;
  min-height: 100px;
  width: calc(100% - 320px);
  max-height: 90%;
  max-width: 1200px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 8px 48px -16px rgba(0, 0, 0, 0.4);
  z-index: 11;
  animation: slide-up 0.5s forwards ease;
  -webkit-animation: slide-up 0.5s forwards ease;
  overflow-x: hidden;
  overflow-y: scroll;
}

.Close {
  position: absolute;
  top: 12px;
  right: 12px;
  height: 20px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 12;
  border-radius: 50%;
  background: #fff;
  padding: 4px;
}

#Exit {
  animation: drop-out 0.5s forwards ease;
  -webkit-animation: drop-out 0.5s forwards ease;
}

@keyframes slide-up {
  0% {
    transform: translateY(100vh);
  }
  60% {
    transform: translateY(-6vh);
  }
  100% {
    transform: translateY(0vh);
  }
}

@-webkit-keyframes slide-up {
  0% {
    transform: translateY(100vh);
  }
  60% {
    transform: translateY(-6vh);
  }
  100% {
    transform: translateY(0vh);
  }
}

@keyframes drop-out {
  0% {
    transform: translateY(0vh);
  }
  40% {
    transform: translateY(-6vh);
  }
  100% {
    transform: translateY(100vh);
  }
}

@-webkit-keyframes drop-out {
  0% {
    transform: translateY(0vh);
  }
  40% {
    transform: translateY(-6vh);
  }
  100% {
    transform: translateY(100vh);
  }
}

@media only screen and (max-width: 800px) {
  .Background {
    left: 0;
    width: 100vw;
  }
  .ModalLarge {
    width: calc(100% - 32px);
  }
}
