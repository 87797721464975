.edit-item-title{
  margin-top:10px;
  margin-bottom:10px;
  font-weight:700;
  font-size:24px;
}

@media only screen and (max-width: 599px){
  .edit-item-title{
    font-size:18px;
  }
}

.edit-item-label{
  font-weight:600;
  font-size:16px;
}

@media only screen and (max-width: 599px){
  .edit-item-label{
    font-size:14px;
  }
}

.edit-item-input{
  box-sizing:border-box;
  width:100%;
  background-color:#FCFCFC;
  height:40px;
  border: 1px solid #DFDFDF;
  border-radius:4px;
  font-weight:400;
  font-size:16px;
  padding-left:7px;
}

.edit-item-textarea{
  box-sizing:border-box;
  width:100%;
  background-color:#FCFCFC;
  border: 1px solid #DFDFDF;
  border-radius:4px;
  font-weight:400;
  font-size:16px;
  font-family:Poppins;
  padding-left:7px;
}

.edit-item-field-wrapper{
  margin-bottom:40px;
  position:relative;
}

@media only screen and (max-width: 599px){
  .edit-item-field-wrapper{
    margin-bottom:15px;
  }

  .edit-item-input{
    font-size:14px;
  }
}

.edit-item-button{
  width:100px;
  height:40px;
  border-radius:40px;
  background-color:#3440FF;
  font-size:16px;
  font-weight:600;
  color:var(--pure-white);
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
}

.edit-item-button-disabled{
  opacity:0.3;
}

.menu-item-dropdown{
  background: #FCFCFC;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 25px 80px rgba(0, 0, 0, 0.06), 0px 2px 10.0172px rgba(0, 0, 0, 0.3);
  border-radius: 20px 0px 20px 20px;
  height:96px;
  display:flex;
  flex-direction:column;

  width:200px;
  position:absolute;
  z-index:5;
  padding:5px 10px;
}

.menu-item-dropdown-option{
  widtH:100%;
  height:50%;
  box-sizing:border-box;
  display:flex;
  align-items:center;
  justify-content:center;
  color:#262525;
  font-size:16px;
  font-weight:600;
  cursor:pointer;
}


@media only screen and (max-width: 599px){
  .menu-item-dropdown-option{
    font-size:14px;
  }
}

.menu-section-select{
  box-sizing:border-box;
  width:100%;
  background-color:#FCFCFC;
  height:40px;
  border: 1px solid #DFDFDF;
  border-radius:4px;
  font-weight:400;
  font-size:16px;
  padding-left:7px;
}

.desktop-menu-item-page-container{
  width:100%;
  display:flex;
  flex-direction:row;
  align-items:flex-start;
}

.dekstop-menu-item-content-container{
  width:calc(100% - 236px);
  display:flex;
  flex-direction:column;
}

.desktop-menu-item-bottom-content{
  width:100%;
  display:flex;
  box-sizing: border-box;
}

.desktop-menu-item-title{
  font-family:OPTIChampionBold;
  font-size:24px;
  color:#262525;
}

.desktop-menu-item-header-row{
  position:sticky;
  top:50px;
  background-color:white;
  z-index:4;
  padding:20px 0px;
  margin-bottom:20px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  border-bottom:1px solid #DFDFDF;
  padding-left: 35px;
  padding-right: 35px;
}

.desktop-menu-item-buttons-row{
  display:flex;
}

.blue-text-desktop-button{
  height:40px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  font-size:16px;
  font-weight:600;
  border-radius:40px;
  padding:0px 30px;
  color:#3440FF;
  box-sizing:border-box;
  border:1px solid #3440FF;
  background-color:#FCFCFC;
}


.white-text-desktop-button{
  height:40px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  font-size:16px;
  font-weight:600;
  border-radius:40px;
  color:#FCFCFC;
  padding:0px 30px;
  box-sizing:border-box;
  background-color:#3440FF;

}

.white-text-desktop-button svg{
  fill:#FCFCFC;
  height:18px;
  width:18px;
  margin-right:8px;
}

.desktop-menu-item-content-inner-container{
  width:50%;
  box-sizing:border-box;
  padding-right:15px;
  padding-left:40px;
}

.desktop-menu-item-content-modifier-groups{
  width:50%;
  box-sizing:border-box;
  padding-left:15px;
  padding-right:40px;
}

.disabled-button-opaque{
  pointer-events:none;
  opacity:0.7;
}

.desktop-menu-item-modifier-groups-title{
  font-size:20px;
  font-weight:700;
}

.desktop-menu-item-add-modifier-group{
  color:#000CCF;
  font-size:18px;
  font-weight:500;
  display:flex;
  align-items:center;
  margin-top:10px;
}

.desktop-menu-item-add-modifier-group svg {
  fill:#000CCF;
  margin-right:13px;
  height:15px;
  width:15px;
}

.edit-item-image{
  width:100%;
  margin-top:10px;
}

.menu-item-edit-modifier-button-container{
  display:flex;
  width:100%;
  box-sizing:border-box;
  flex-direction:row;
  align-items:center;
  justify-content:flex-start;
  margin-bottom:25px;
  margin-top:9px;
}


.menu-item-edit-modifier-button{
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
}

.menu-item-edit-modifier-button svg{
  width:14px;
  height:14px;
  fill:#000CCF;
  margin-right:8px;
}
