.orders-rest-name{
  font-family:OPTIChampionBold;
  font-weight:900;
  font-size:20px;
  padding:10px 0px;
}

@media only screen and (min-width: 599px){
  .orders-rest-name{
    font-size:24px;
  }
}
