.full-page-card-tablet-light-button{
  width:100%;
  border-radius:40px;
  display:flex;
  align-items:center;
  justify-content:center;
  border: 1px solid #000CCF;
  color:#000CCF;
  font-size:16px;
  font-weight:600;
  font-family:Poppins;
  height:40px;
  box-sizing:border-box;
}

.full-page-card-tablet-light-button svg{
  fill:#000CCF;
  margin-left:5px;
  height:18px;
  width:18px;
}

.full-page-card-tablet-action-button{
  width:100%;
  border-radius:40px;
  display:flex;
  align-items:center;
  justify-content:center;
  color:#000CCF;
  font-size:16px;
  font-weight:600;
  font-family:Poppins;
  height:40px;
  background-color:#000CCF;
  color:#FCFCFC;
}

.full-page-card-tablet-gray-button{
  width:100%;
  border-radius:40px;
  display:flex;
  align-items:center;
  justify-content:center;
  color:#000CCF;
  font-size:16px;
  font-weight:600;
  font-family:Poppins;
  height:40px;
  background-color:#8C8C8C;
  color:#FCFCFC;
}



.hr-grey{
  width:100%;
  height:1px;
  border-bottom:1px solid #DFDFDF;
  margin-top:20px;
  margin-bottom:20px;
}

.full-page-card-tablet-times{
  margin-top:20px;
}

.full-page-card-tablet-time{
  color:#262525;
  font-family:Poppins;
  font-size:16px;
  font-weight:600;
  width:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  height:40px;
}

.full-page-card-tablet-time-selected{
  color:green;
}

.sidebar-more-options-container{
  font-size:16px;
  font-weight:600;
  font-family:Poppins;
  color: #000CCF;
  position:absolute;
  bottom:15px;
  width:225px;
  text-align:center;
  right:0px;
}

.sidebar-more-options-container svg{
  fill: #000CCF;
  height:16px;
  width:16px;
  position:relative;
  top:1px;
  left:2px;
}

.more-options-container-open{
  color: #757575;
}

.more-options-container-open svg{
  fill: #757575;
}

.more-options-cancel{
  font-family:Poppins;
  font-weight:600;
  font-size:16px;
  color:#D14848;
  padding:11px 0px;
}

.more-options-adjust{
  font-family:Poppins;
  font-weight:600;
  font-size:16px;
  color:#262525;
  padding:11px 0px;
}

.tablet-disabled-subtext{
  width:100%;
  text-align:center;
  font-weight:500;
  font-family:Poppins;
  font-size:14px;
  color:#8C8C8C;
  margin-top:15px;
}

.select-ready-time-text{
  font-size:16px;
  font-weight:600;
  font-family:Poppins;
  text-align:center;
}

.ready-time-button{
  margin:5px 0px;
}

.full-page-card-tablet-time-unselected{
  color:#9d9d9d !important;
  border-color:#9d9d9d !important;
}
