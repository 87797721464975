.bg-pink {
  background: var(--p-pink);
}

.bg-blue {
  background: var(--p-blue);
}

.flex {
  display: flex;
}

.block {
  display: block;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-inline {
  display: inline-flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-apart {
  justify-content: space-between;
}

.flex-justify-center {
  justify-content: center;
}
.flex-justify-start {
  justify-content: flex-start;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-grow {
  flex-grow: 1;
}

.flex-evenly {
  justify-content: space-evenly;
}

.flex-align-center {
  align-items: center;
}

.flex-basis-50 {
  display: flex;
  flex-basis: 50%;
}

.flex-basis-35 {
  display: flex;
  flex-basis: 35%;
}

.flex-grow-1 {
  display: flex;
  flex: 1 1 0;
}

.flex-grow-2 {
  display: flex;
  flex: 2 1 0;
}

.align-self-start {
  display: flex;
  align-self: flex-start;
}

.no-wrap {
  white-space: nowrap;
}

.button-default {
  background: transparent;
  border: none;
  cursor: pointer;
}

.br-b {
  border-bottom: 1px solid black;
}

.border-2 {
  border-width: 2px;
}

.border-1 {
  border-width: 1px;
}

.br-blue {
  border-color: var(--p-blue);
}

.br-grey {
  border-color: var(--p-grey);
}

.br-pink {
  border-color: var(--p-pink);
}

.rel {
  position: relative;
}

.absolute {
  position: absolute;
}

.ml-auto {
  margin-left: auto;
}
.mh-auto {
  margin-left: auto;
  margin-right: auto;
}

.mt-auto {
  margin-top: auto;
}

.mr-auto {
  margin-right: auto;
}

.x-auto {
  overflow-x: auto;
}

.y-auto {
  overflow-y: auto;
}

@function spacing($size) {
  $result: 1;
  $result: $size * 0.25;
  @return #{$result}rem;
}

@for $i from 0 through 20 {
  //margin utility

  .m-#{$i} {
    margin: spacing($i);
  }

  .mv-#{$i} {
    margin-top: spacing($i);
    margin-bottom: spacing($i);
  }

  .mh-#{$i} {
    margin-left: spacing($i);
    margin-right: spacing($i);
  }

  .mt-#{$i} {
    margin-top: spacing($i);
  }

  .mb-#{$i} {
    margin-bottom: spacing($i);
  }

  .mr-#{$i} {
    margin-right: spacing($i);
  }

  .ml-#{$i} {
    margin-left: spacing($i);
  }

  // Padding utility

  .p-#{$i} {
    padding: spacing($i);
  }

  .pv-#{$i} {
    padding-top: spacing($i);
    padding-bottom: spacing($i);
  }

  .ph-#{$i} {
    padding-left: spacing($i);
    padding-right: spacing($i);
  }

  .pt-#{$i} {
    padding-top: spacing($i);
  }

  .pb-#{$i} {
    padding-bottom: spacing($i);
  }

  .pr-#{$i} {
    padding-right: spacing($i);
  }

  .pl-#{$i} {
    padding-left: spacing($i);
  }
}

@media only screen and (max-width: 800px) {
  .mq {
    &__flex-wrap {
      flex-wrap: wrap;
    }

    &__flex-align-center {
      align-items: center;
    }

    &__flex-col {
      flex-direction: column;
    }

    &__m-0 {
      margin: 0;
    }

    @for $i from 0 through 20 {
      //margin utility

      &__m_#{$i} {
        margin: spacing($i);
      }

      &__mv-#{$i} {
        margin-top: spacing($i);
        margin-bottom: spacing($i);
      }

      &__mh-#{$i} {
        margin-left: spacing($i);
        margin-right: spacing($i);
      }

      &__mt-#{$i} {
        margin-top: spacing($i);
      }

      &__mb-#{$i} {
        margin-bottom: spacing($i);
      }

      &__mr-#{$i} {
        margin-right: spacing($i);
      }

      &__ml-#{$i} {
        margin-left: spacing($i);
      }

      // Padding utility

      &__p-#{$i} {
        padding: spacing($i);
      }

      &__pv-#{$i} {
        padding-top: spacing($i);
        padding-bottom: spacing($i);
      }

      &__ph-#{$i} {
        padding-left: spacing($i);
        padding-right: spacing($i);
      }

      &__pt-#{$i} {
        padding-top: spacing($i);
      }

      &__pb-#{$i} {
        padding-bottom: spacing($i);
      }

      &__pr-#{$i} {
        padding-right: spacing($i);
      }

      &__pl-#{$i} {
        padding-left: spacing($i);
      }
    }
  }
}
