.modifier-class-top-of-page{
  width:100%;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
}

.modifier-class-top-of-page-left{
  max-width: calc(100% - 135px);
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:space-between;
}

.modifier-class-internal-name-header{
  color:#595959;
  font-weight:700;
  font-size:16px;
}

.modifier-class-title{
  font-family:OPTIChampionBold;
  font-weight:900;
  font-size:20px;
}

.modifier-class-top-options-container{
  display:flex;
  align-items:center;
  justify-content:center;
  border-radius:8px;
  padding:3px 6px;
  color:#262525;
  font-size:14px;
  font-weight:600;
  background: #DFDFDF;
}

@media only screen and (max-width: 599px){
  .modifier-class-internal-name-header{
    font-size:14px;
  }
  .modifier-class-title{
    font-size:16px;
  }

  .modifier-class-top-options-container{
    font-size:12px;
  }
}

.modifier-class-edit-group{
  height:40px;
  font-size:16px;
  font-weight:600;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:row;
  border: 1px solid #000CCF;
  border-radius:40px;
  color: #000CCF;
  padding:0px 20px;
}

.modifier-class-edit-group svg{
  color: #000CCF;
  width:22px;
  height:22px;
  margin-right:10px;
}

@media only screen and (max-width: 599px){
  .modifier-class-edit-group{
    font-size:14px;
    padding:0px 15px;
  }

  .modifier-class-edit-group svg{
    width:20px;
    height:20px;
  }
}

.modifier-class-small-hr{
  width:120px;
  height:1px;
  background:#8C8C8C;
  margin-top:15px;
  margin-bottom:15px;
}

.modifier-options-title-row{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  margin-bottom:15px;
}

.modifier-options-title{
  font-size:20px;
  color:#262525;
  font-weight:700;
}

@media only screen and (max-width: 599px){
  .modifier-options-title{
    font-size:16px;
  }
}

.modifier-class-add-modifier{
  display:flex;
  flex-direction:row;
  align-items:center;
  font-size:16px;
  font-weight:600;
  color:#000CCF;
}

.modifier-class-add-modifier svg{
  margin-right:8px;
  height:16px;
  width:16px;
  fill:#000CCF;
  position:relative;
}


@media only screen and (max-width: 599px){
.modifier-class-add-modifier{
    font-size:14px;
  }

  .modifier-class-add-modifier svg{
    height:14px;
    width:14px;
  }
}
