.desktop-choose-rest-container{
  padding:20px;
}



.choose-rest-brand-dropdown-container option, .choose-rest-brand-dropdown-container select{
  font-size:25px;
  font-family:Poppins;
}

.choose-rests-rests{
  font-size:20px;
  font-family:Poppins;
  color:blue;
  text-decoration:underline;
  padding-top:10px;
  padding-bottom:10px;
  cursor:pointer;
}

.choose-rests-rests:hover{
  text-decoration:underline;
}

.choose-rests-selected{
  font-weight:900;
}
