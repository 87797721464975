.menu-search-result-section-title{
  font-size:20px;
  font-weight:700;
  color:#262525;
  font-family:Poppins;
  margin-bottom:15px;
  margin-top:30px;
}

.menu-search-num-results{
  margin-top:10px;
  margin-bottom:10px;
  color:#757575;
  font-size:12px;
  font-weight:400;
}

