.login-page{
  box-sizing:border-box;
  min-height:100vh;
  width:100%;
  background-color: #EEEEEE;
  box-sizing:border-box;
  padding-top:env(safe-area-inset-top);
  overflow: auto;
  padding:0px 20px;
}

.login-content{
  background-color:var(--pure-white);
  padding:24px;
}

.login-sms-icon-container svg{
  fill:var(--captain-black);
  height:40px;
  width:40px;
  margin-bottom:15px;
}

.login-phone-icon-container svg{
  fill:var(--captain-blue);
  height:75px;
  width:75px;
  margin-bottom:15px;
}

.login-header{
  font-family:Poppins;
  font-weight:900;
  color:var(--captain-black);
  font-size:24px;
  margin-bottom:10px;
}

.login-subheader{
  color:#262525;
  font-family:Poppins;
  font-weight:400;
  font-size:16px;
  margin-bottom:10px;
}

.login-sub-subheader{
  font-family:Poppins;
  font-weight:400;
  font-size:12px;
  color:#757575;
}

.login-phone-row{
  margin-top:30px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-start;
}

.login-plus-1{
  font-family:Poppins;
  font-size:24px;
  font-weight:600;
  width:35px;
  margin-right:10px;
}

.login-phone-input{
  width:calc(100% - 35px);
  max-width:500px;
  height:36px;
  border-radius:2px;
  font-family:Poppins;
  font-size:16px;
  font-weight:400;
  color:#757575;
  padding-left:15px;
}

.login-code-input{
  height:36px;
  border-radius:2px;
  font-family:Poppins;
  font-size:16px;
  font-weight:400;
  color:#757575;

}

.login-phone-button{
  height:40px;
  border-radius:40px;
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  font-size:16px;
  font-weight:600;
  font-family:Poppins;
  margin-top:50px;
}

.login-phone-button-disabled{
  background-color:var(--element-grey);
  color:var(--line-grey);
  pointer-events:none;
}

.login-phone-button-enabled{
  color:var(--pure-white);
  background-color:var(--captain-blue);
}

.login-phone-error{
  color:var(--error-red);
  font-family:Poppins;
  font-weight:400;
  font-size:14px;
}

.resend-code-link{
  color:#757575;
  font-family:Poppins;
  text-decoration:underline;
  font-weight:400;
  font-size:14px;
  margin-top:30px;
  text-align:center;
}

.login-back-wrapper{
  height:70px;
}

.login-back-container{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-start;
  font-family:Poppins;
  font-weight:500;
  font-size:18px;
  color:var(--captain-black);
}


.login-back-container svg{
  fill:var(--captain-black);
  margin-right:20px;
  height:20px;
  width:20px;
}

.choose-rest-header{
  font-family:Poppins;
  font-weight:900;
  font-size:36px;
  color:var(--captain-black);
}

.choose-rest-rest-card{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-start;
  border: 1px solid #DFDFDF;
  box-sizing: border-box;
  border-radius: 4px;
  min-height:130px;
  background-color:var(--pure-white);
  padding:30px;
  margin-bottom:30px;
  box-shadow: 0px 4px 45px rgba(11, 18, 70, 0.4), 0px 0.500862px 5.6347px rgba(11, 18, 70, 0.2);
}

.choose-rest-right-side{
  display:flex;
  flex-direction:column;
}

.choose-rest-right-side-row{
  font-family:Poppins;
  font-size:18px;
  font-weight:500;
}

.choose-rest-name{
  font-weight:700 !important;
}

.choose-rest-subheader{
  font-size:16px;
  font-weight:400;
  color:#262525;
  font-family:Poppins;
  margin-top:20px;
  margin-bottom:20px;
}

.choose-rest-logo{
  max-width: 80px;
  margin-right:30px;
}

.phone-input-error{
  background: #FCF5F5;
  border: 1px solid #D14848;
}
