.terminal-connected{
  box-sizing:border-box;
  padding-left:20px;
  width:100%;
  height:50px;
  display:flex;
  align-items:center;
  background-color:#239C44;
  color:white;
  font-size:18px;
  font-weight:500;
  margin-top:5px;
}

.terminal-connected svg{
  height:18px;
  margin-right:5px;
  fill:white;
}
