.menu-modal-background{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction:column;
  justify-content: flex-end;
  align-items: center;
  z-index: 12;
  overflow: hidden;
  animation: fade-in .3s forwards ease;
  -webkit-animation: fade-in .3s forwards ease;
  background: rgba(0,0,0,0.5);
}

.menu-modal-container{
  width:90%;
  height:80vh;
  background-color:var(--pure-white);
  margin-left:auto;
  margin-right:auto;
  border-top-left-radius:20px;
  border-top-right-radius:20px;
  padding-top:20px;
  padding-left:20px;
  padding-right:20px;
  overflow-x:hidden;
  overflow-y:auto;
}

@media only screen and (min-width: 1000px){
  .menu-modal-container{
    width:400px;
    height:80vh;
    top:10vh;
    position:absolute;
    border-radius:20px !important;
  }
}

.menu-modal-title{
  font-size:24px;
  font-weight:900;
  font-family:OPTIChampionBold;
  color:#262525;
}

@media only screen and (max-width: 599px){
  .menu-modal-title{
    font-size:20px;
  }
}

.menu-modal-items-container{
  margin-top:25px;
}


@keyframes fade-out {
  0%{
    background: rgba(0,0,0,.5);
  }
  100%{
    background: rgba(0,0,0,0);
  }
}

@keyframes fade-in {
  0%{
    background: rgba(0,0,0,0);
  }
  100%{
    background: rgba(0,0,0,0.5);
  }
}

