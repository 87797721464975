.text {
  font-size: 1rem;
}

.text--xs {
  font-size: 0.6rem;
}

.text--s {
  font-size: 0.75rem;
}

.text--m {
  font-size: 1rem;
}

.text--l {
  font-size: 1.25rem;
}

.text--xl {
  font-size: 1.5rem;
}

.text--header {
  font-size: 2rem;
}

.text--regular {
  font-weight: 400;
}

.text--bold {
  font-weight: 600;
}

.text--extrabold {
  font-weight: 700;
}

.text--black {
  font-weight: 900;
}

.text--color-blue {
  -webkit-text-fill-color: #01035e;
  color: #01035e;
}

.text--color-grey {
  -webkit-text-fill-color: #888888;
  color: #888888;
}

.text--color-white {
  -webkit-text-fill-color: white;
  color: white;
}

.text--color-black {
  -webkit-text-fill-color: #000000;
  color: #000000;
}

.text--color-pink {
  -webkit-text-fill-color: #d62e70;
  color: #d62e70;
}

.text--left {
  text-align: left;
}

.text--center {
  text-align: center;
}

.text--right {
  text-align: right;
}
