.order-details-tablet-container{
  width:100%;
}

.order-details-placed-tablet{
  font-size:14px;
  font-family:Poppins;
  font-weight:500;
  color:#757575;
}

.order-details-order-type-tablet{
  font-family:OPTIChampionBold;
  font-size:20px;
  font-weight:900;
  color:#0B1246;
}

.order-details-tablet-bottom-border-container{
  padding-top:15px;
  width:100%;
  border-bottom:1px solid #DFDFDF;
  padding-bottom:15px;
}

.order-details-tablet-due-in{
  font-family:Poppins;
  font-weight:500;
  font-size:16px;
  color:#757575;
}

.order-details-tablet-timer{
  font-family:Poppins;
  font-weight:500;
  font-size:18px;
  color:#262525;
}
