.card-crypto-container{
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  height:58px;
  border: 1px solid #000CCF;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  margin-top:30px;
}

.card-crypto-button{
  box-sizing:border-box;
  width:50%;
  height:54px;
  border-radius: 3px;
  display:flex;
  align-items:center;
  justify-content:center;
}

.card-crypt-button-selected{
  background-color:#000CCF;
  color:white;
  pointer-events:none;
}

.card-crypt-button-unselected{
  color:#000CCF;
  cursor:pointer;
}

.foodfight-row{
  display:flex;
  width:100%;
  align-items:center;
  justify-content:center;
  height:70px;
  font-size:30px;
  font-weight:900;
  border-top:3px solid black;
  border-bottom:3px solid black;
}

.foodfight-row input{
  width: 70px;
  padding: 5px;
  margin-right: 15px;
  font-size: 40px;
}

.qr-container{
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  padding-top:10px;
  font-size:50px;
  font-weight:900;
}

.food-paid{
  font-size:80px;
  font-weight:900;
  color:green;
}

.foodfight-row-disabled{
  opacity:0.3;
  pointer-events:none;
}
