.header-wrapper{
  position:sticky;
  top:0px;
  z-index:9;
}

.orders-header-container{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  height:68px;
  box-sizing:border-box;
}

@media only screen and (max-width: 599px){
  .orders-header-container{
    height:56px;
  }
}


.orders-header-container-no-unconfirmed-orders *{
  fill: black;
}

.orders-header-container-unconfirmed-orders{
  background-color:#239C44;
}

.header-captain-logo{
  height:30px;
  width:30px;
  fill:#0B1246;
  height:30px;
  width:30px;
}

.header-captain-logo *{
  fill:#0B1246;
  color:#0B1246;
}

.orders-header-header{
  color:white;
  font-weight:900;
  font-family: OPTIChampionBold;
  font-size:16px;
}

.orders-header-subheader{
  color:white;
  font-family:Poppins;
  font-weight:600;
  font-size:12px;
  font-weight:600;
}

.order-header-inner-wrapper{
  width:100%;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
}


.header-icon-container{
  height:100%;
  width:70px;
  display:flex;
  align-items:center;
  justify-content:center;
  padding:0px 15px;
  position:relative;
  min-height:1px;
}

.header-alert-circle{
  position:absolute;
  width:25px;
  height:25px;
  border-radius:40px;
  display:flex;
  align-items:center;
  justify-content:center;
  top: 4px;
  right: 18px;
  background-color:#EA5151;
  color:#FCFCFC !important;
  font-family:Poppins;
  font-weight:600;
  font-size:16px;
}

.white-bar{
  width: 100%;
  background-color: white;
  height: 8px;
  position: sticky;
  top: 0px;
  z-index: 10;
}

.header-captain-logo{
  height:30px;
  width:30px;
  fill:#0B1246;
}

.header-bell{
  fill:#0B1246;
  height:20px;
  width:20px;
}

.yes-orders-header{
  background-color:#239C44;
}

.no-orders-header{
  background-color:#f4f4f4;
}

.header-back{
  fill:#FCFCFC;
  height:20px;
}

.no-orders-header .header-back{
  fill:#0B1246;
}

.closed-banner{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  background-color:#F0CF58;
  width:100%;
  height:60px;
  padding:0px 20px;
  box-sizing:border-box;
}


.closed-banner svg{
  fill:#262525;
  height:25px;
  position:relative;
  top:-2px;
  margin-right:7px;
}

.closed-banner-left{
  font-family:Poppins;
  font-size:16px;
  font-weight:700;
  color:#262525;
  display:flex;
  align-items:center;
  height:60px;
}

.closed-banner-change{
  font-family:Poppins;
  font-size:16px;
  font-weight:400;
  text-decoration:underline;
  color:#262525;
}

@media only screen and (max-width: 599px){
  .closed-banner-left{
    font-size:13px;
  }

  .closed-banner-change{
    font-size:13px;
  }

}


.ios-notch-height{
  height: env(safe-area-inset-top);
}
