.order-toast{
  width:100%;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-start;
  color:#262525;
  height:70px;
  position:fixed;
  bottom:70px;
  z-index:50;
  width: calc(100% - 40px);
  left:20px;
  border-radius:4px;
  background-color: #FCFCFC !important;
  box-sizing:border-box;
  padding:15px 0px;
  /*box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;*/
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12);
  animation: slide-up .3s forwards ease;
  -webkit-animation: slide-up .3s forwards ease;
  -webkit-overflow-scrolling: touch;
}


@media only screen and (min-width: 1000px){
  .order-toast{
    bottom:20px;
    right:20px;
    width:555px;
    left:auto;
  }
}

.order-toast-confirmed , .order-toast-upcharge, .order-toast-ready,  .order-toast-reactivated {
  border-left: 10px solid #239C44; 
}

.order-toast-confirmed svg, .order-toast-upcharge svg, .order-toast-ready svg ,  .order-toast-reactivated svg {
  fill:#239C44;
}

.order-toast-cancelled , .order-toast-refund , .order-toast-expired, .order-toast-deleted {
  border-left: 10px solid #D14848;
}

.order-toast-cancelled svg, .order-toast-refund svg, .order-toast-expired svg, .order-toast-deleted svg{
  fill:#D14848;
}

.order-toast-snoozed , .order-toast-adjustment , .order-toast-deactivated, .order-toast-item-edit, .order-toast-modifier-class-edit, .order-toast-modifier-added , .order-toast-generic {
  border-left: 10px solid #000CCF;
}

.order-toast-snoozed svg, .order-toast-adjustment svg , .order-toast-deactivated svg, .order-toast-item-edit svg, .order-toast-modifier-class-edit svg  .order-toast-modifier-added svg, .order-toast-generic svg {
  fill:#000CCF;
}


.order-toast-left{
  padding: 0px 12px;
}

.order-toast-left svg{
  width:20px;
  height:20px;
}

.order-toast-right {
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:flex-start;
  box-sizing: border-box;
  padding: 8px;
  height:70px;
  width:calc(100% - 44px);
}

.order-toast-order-type{
  font-family:Poppins;
  font-weight:900;
  font-size:18px;
}

.order-toast-diner-name{
  font-family:Poppins;
  font-weight:600;
  font-size:18px;
}

.order-toast-total{
  font-size:16px;
  font-weight:400;
  font-family:Poppins;
}

.order-toast-prep-time{
  font-family:Poppins;
  font-weight:400;
  font-size:14px;
}

.order-toast-prep-time svg{
  fill:#FCFCFC;
  height:14px;
  width:14px;
}

@keyframes slide-up {
  0%{
    transform: translateY(100vh);
  }
  100%{
    transform: translateY(0vh);
  }
}

@-webkit-keyframes slide-up {
  0%{
    transform: translateY(100vh);
  }
  100%{
    transform: translateY(0vh);
  }
}

@keyframes fade-in {
  0%{
    background: rgba(0,0,0,0);
  }
  100%{
    background: rgba(0,0,0,0.5);
  }
}
