.choose-reader-title{
  font-family:Poppins;
  font-size:24px;
  font-weight:600;
  margin-bottom:15px;
  margin-top:10px;
}

.choose-reader-subtitle{
  font-family:Poppins;
  font-size:15px;
  font-weight:500;
}

.choose-reader-no-readers{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
}



.choose-reader-no-readers{
  margin-top:30px;
  font-size:18px;
}

.choose-reader-no-readers svg{
  width:40px;
  height:40px;
  fill:red;
}

.choose-reader-no-reader-text{
  width: calc(100% - 60px);
  color:red;
}

.choose-reader-refresh-list-container{
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  margin-top:30px;
}

.choose-reader-refresh-list{
  display:flex;
  align-items:center;
  justify-content:center;
  height:40px;
  background-color:var(--captain-blue);
  border-radius:40px;
  color:white;
  font-size:20px;
  font-weight:18px;
  padding:10px;
  font-weight:700;
}

.choose-reader-refresh-list svg{
  margin-left:10px;
  fill:white;
}

.choose-reader-reader{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  padding-top:10px;
  padding-bottom:10px;
  border-bottom: 1.2375px solid #DFDFDF;
}

.choose-reader-name{
  font-family:Poppins;
  font-size:18px;
  font-weight:600;
}

.choose-reader-connect{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  color:#000CCF;
  font-size:16px;
  font-family:Poppins;
  font-weight:600;
  border:1px solid #000CCF;
  height:40px;
  border-radius:40px;
  padding-left:10px;
  padding-right:10px;
}

.choose-reader-disconnect{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  color:#D14848;
  font-size:16px;
  font-family:Poppins;
  font-weight:600;
  border:1px solid #D14848; 
  height:40px;
  border-radius:40px;
  padding-left:10px;
  padding-right:10px;
}
