.tax-exempt-diner{
  display:flex;
  align-items:center;
  justify-content:space-between;
  width:100%;
  box-sizing:border-box;
  border-bottom: 1px solid #AAABAF;
  height:99px;
}

.tax-exempt-diner-phone{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}

.tax-exempt-diner-name{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.tax-exempt-diner-info{
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  margin-left:12px;
} 

.tax-exempt-diner-left{
  display:flex;
}
