body{
  margin-left:0px;
  margin-bottom:0px;
  margin-right:0px;
  margin-top:0px;
  color: #262525;
  font-family:Poppins;
}

html, body, #root, #app-container{
  width:100%;
}

.error-red-text{
  color:#D14848 !important;
}

.red{
  color:#D14848 !important;
}

.error-red-background{
  background-color:#D14848 !important;
}

.page-content{
  padding-top:15px;
  width:100%;
  box-sizing:border-box;
  padding-left:20px;
  padding-right:20px;
}


.content{
  width:100%;
  box-sizing:border-box;
  padding-left:20px;
  padding-right:20px;
}

.orders-content{
  width:100%;
  box-sizing:border-box;
  padding-left:20px;
  padding-right:20px;
  padding-bottom:calc(56px + var(--inset-bottom));
}

@media only screen and (min-width: 1000px){
  .orders-content{
    padding-left:40px;
    padding-right:40px;
    padding-bottom:0px;
  }
}

.clickable{
  cursor:pointer;
}

.space-between{
  display:flex;
  align-items:center;
  justify-content:space-between;
  width:100%;
}

@font-face {
  font-family: OPTIChampionBold;
  src: url("fonts/OPTIChampionBold.otf") format("opentype");
}

@font-face {
  font-family: Poppins;
  src: url("fonts/Poppins-Regular.ttf") format("truetype");
  font-weight:400;
}

@font-face {
  font-family: Poppins;
  src: url("fonts/Poppins-Medium.ttf") format("truetype");
  font-weight:500;
}

@font-face {
  font-family: Poppins;
  src: url("fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight:600;
}

@font-face {
  font-family: Poppins;
  src: url("fonts/Poppins-Bold.ttf") format("truetype");
  font-weight:700;
}

@font-face {
  font-family: Poppins;
  src: url("fonts/Poppins-ExtraBold.ttf") format("truetype");
  font-weight:900;
}

.active-green{
  color: #34A853;
}

.green{
  color: #34A853 !important;
}

.mt-15{
  margin-top:15px;
}

.justify-center{
  justify-content:center !important;
}

.flex-center{
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:row;
}

.invisible{
  opacity:0;
}

.white{
  color: #FCFCFC !important;
  fill: #FCFCFC !important;
}

.white-background{
  background-color:#FCFCFC !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

.input-error{
  background-color:var(--utility-error-red-hue) !important;
  border: 1px solid #D14848 !important;
}


.page-header{
  margin-top:15px;
  margin-bottom:25px;
  font-family:OPTIChampionBold;
  font-weight:900;
  font-size:24px;
  color:var(--captain-black);
}

@media only screen and (max-width: 599px){
  .page-header{
    font-size:18px;
  }
}

.disabled-button{
  background-color:#DFDFDF !important;
  color:#8C8C8C !important;
  pointer-events:none;
}

.app-container-ios{
  padding-top:50px;
  box-sizing:border-box;
}

:root {
  --inset-top: env(safe-area-inset-top);
  --inset-right: env(safe-area-inset-right);
  --inset-bottom: env(safe-area-inset-bottom);
  --inset-left: env(safe-area-inset-left);
}

.displaynone{
  display:none !important;
}

.flex-start{
  justify-content:flex-start !important;
}

.checkbox{
  width:18px;
  height:18px;
  box-sizing:border-box;
  border-radius:3px;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
}

.checkbox-unchecked{
  background-color:white;
  border:2px solid #979797;
}

.checkbox-unchecked svg{
  display:none;
}

.checkbox-checked{
  background-color:#0B1246;
}

.checkbox-checked svg{
  width:10px;
  height:10px;
  fill:white;
}

.flexstart{
  display:flex;
  flex-direction:row;
  align-items:center;
}
