.search-bar-container{
  position:relative;
  box-sizing:border-box;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  margin-top:20px;
}

.search-bar-inner-container-sticky{
  position:relative;
  top:-10px;
  width:100%;
  padding-left:20px;
  padding-right:20px;
  left:-20px;
  padding-bottom:25px;
  padding-top:20px;
  background-color:white;
  height:30px;
  z-index:99;
}

.search-bar-container-sticky{
  position:sticky !important;
  background-color:white;
  top:138px ;
  z-index:10;
}

@media only screen and (max-width: 599px){
  .search-bar-container-sticky{
    top:126px;
  }
}

.search-bar-input{
  box-sizing:border-box;
  border: 1px solid #DFDFDF;
  font-size:16px;
  font-weight:400;
  font-family:Poppins;
  color:#757575;
  padding-left:36px;
  height:40px;
  background-color:#F4F4F4;
  border-radius:2px;
}


@media only screen and (max-width: 599px){
  .search-bar-input{
    font-size:14px;
  }
}

.search-bar-input-search-page {
  width:calc(100% - 80px);
}

.search-bar-icon{
  height: 15px;
  width: 15px;
  position: absolute;
  left: 18px;
  top: 18px;
}

.search-cancel{
  font-family:Poppins;
  font-weight:400;
  font-size:16px;
  color:var(--captain-blue);
}


@media only screen and (max-width: 599px){
  .search-cancel{
    font-size:14px;
  }
}

.search-what-you-looking-for{
  box-sizing:border-box;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  background-color: #F0F0F0;
  border: 1px solid #8C8C8C;
  border-radius: 4px;
  color: #919397;
  padding: 20px 40px;
  margin-top:30px;
}

.search-what-you-looking-for-header{
  font-family:OPTIChampionBold;
  font-weight:900;
  font-size:24px;
  margin-bottom:30px;
}

.search-what-you-looking-for-text{
  font-family:Poppins;
  font-weight:500;
  font-size:14px;
}


@media only screen and (max-width: 599px){
  .search-what-you-looking-for-header{
    font-size:18px;
  }

  .search-what-you-looking-for-text{
    font-size:12px;
  }

}

.search-bucket{
  margin-top:30px;
}
