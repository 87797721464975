.settings-title{
  font-family:OPTIChampionBold;
  font-weight:900;
  font-size:18px;
  margin-top:10px;
  margin-bottom:10px;
}

@media only screen and (max-width: 599px){
  .settings-title{
    font-size:16px;
  }
}

.settings-row{
  display:flex;
  width:100%;
  box-sizing:border-box;
  align-items:center;
  justify-content:space-between;
  height:40px;
}

.advanced-settings-row{
  display:flex;
  width:100%;
  box-sizing:border-box;
  align-items:center;
  justify-content:space-between;
  height:40px;
}

.settings-row-icon{
  height:20px;
  width:20px;
  fill:#0B1246;
  margin-right:15px;
}

.settings-row-label{
  font-family:Poppins;
  font-size:16px;
  font-weight:500;
}


@media only screen and (max-width: 599px){
  .settings-row-label{
    font-size:14px;
  }
}

.settings-row-chevron-right{
  height:20px;
  width:20px;
}

@media only screen and (max-width: 599px){
  .settings-row-chevron-right{
    height:18px;
    width:18px;
  }
}


.settings-row-left{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
}

.settings-close-early-button-container{
  width:100%;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
}

.settings-close-early-left{
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:center;
}

.settings-close-early-button-container{
  height:100px;
  background-color:#e1faeb;
  border-radius:5px;
  padding:8px 12px;
  box-sizing:border-box;
}

.settings-close-early-button{
  height:40px;
  border-radius:20px;
  display:flex;
  align-items:center;
  justify-content:center;
  font-family:Poppins;
  font-size:16px;
  font-weight:600;
  color:#000CCF;
  border: 2px solid #000CCF;
  padding:0px 10px;
}


@media only screen and (max-width: 599px){
  .settings-close-early-button{
    font-size:14px;
  }

  .settings-close-early-button-container{
    height:80px;
  }
}

.settings-close-early-button-turn-off{
  border:2px solid #EA5151;
  color: #EA5151;
  background-color:#F3EBEB;
}

.settings-close-early-type{
  font-size:18px;
  font-family:Poppins;
  font-weight:700;
}

.settings-close-early-status{
  font-size:16px;
  font-family:Poppins;
  font-weight:600
}

@media only screen and (max-width: 599px){
  .settings-close-early-type{
    font-size:16px;
  }

  .settings-close-early-status{
    font-size:14px;
  }
}

.settings-rows-container, .settings-rows-container-no-border {
  padding:10px 0px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  border-bottom: 1px solid #F0F0F0;
  box-sizing:border-box;
}

.advanced-settings-container{
  border-bottom: 1px solid #F0F0F0;
}

.settings-rows-container-no-border {
  border-bottom-width:0px;
}

.prep-time-row2{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  height:54px;
  box-sizing:border-box;
}

.prep-time-left{
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:space-between;
}

.settings-row-title-subtitle-container{
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:space-between;
}

.prep-time-right{
  width:215px;
  height:54px;
  background-color:#F4F4F4;
  border-radius:4px;
  border:1px solid #DFDFDF;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  padding-left:10px;
  padding-right:10px;
  position:relative;
}

.prep-time-input{
  width:125px;
  height:24px;
  font-size:18px;
  font-weight:400;
  font-family:Poppins;
  padding-left:15px;
  pointer-events:none;
}

.prep-time-minutes-label{
  font-size:18px;
  font-weight:400;
  font-family:Poppins;
  position:absolute;
  right:65px;
}

.prep-time-row2 svg{
  fill:#0B1246;
  height:20px;
  width:20px;
}


@media only screen and (max-width: 599px){
  .prep-time-input{
    font-size:16px;
  }

  .prep-time-minutes-label{
    font-size:16px;
  }

  .prep-time-row2 svg{
    height:18px;
    width:18px;
  }

}

.settings-close-early-buttons-container{
  margin-top:15px;
}
