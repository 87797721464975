.button-wrapper__container {
  background: transparent;
  padding: 0;
  z-index: 1;
  cursor: pointer;
  border: none;
}

.button-wrapper--with-border {
  border: inherit;
}
