.deactivate-modal-container{
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  width:340px;
  padding:30px;
  border-radius:10px;
  background-color: rgba(252, 252, 252, 0.95);
}

.deactivate-modal-warning{
  width:100px;
  height:100px;
  fill:#EA5151;
}

.deactivate-modal-something-went-wrong{
  color:#0B1246;
  font-family:Poppins;
  font-weight:700;
  font-size:18px;
  margin-top:30px;
  margin-bottom:30px;
  text-align:center;
}

.deactivate-modal-back-to-kitchen-button{
  background-color:#EA5151;
  color:#FCFCFC;
  display:flex;
  align-items:center;
  justify-content:center;
  height:40px;
  width:100%;
  font-family:Poppins;
  font-weight:600;
  font-size:16px;
  border-radius:40px;
}

.deactivate-modal-title{
  font-family:OPTIChampionBold;
  font-weight:900;
  font-size:24px;
  color:#262525;
}

.deactivate-item-until{
  font-family:Poppins;
  font-weight:400;
  font-size:20px;
  color:#262525;
  margin-top:25px;
  margin-bottom:8px;
}

.deactivate-input{
  height:42px;
  width:100%;
  font-family:Poppins;
  font-weight:400;
  font-size:20px;
  border: 1px solid #DFDFDF;
  box-sizing:border-box;
  background-color:white;
  padding-left:10px;
}

.deactivate-input:focus{
  border: 1px solid #DFDFDF;
  outline: none;
}

.deactivate-today-hack{
  position:absolute;
  top:0px;
  z-index:999;
  height:42px;
  width:200px;
  font-family:Poppins;
  font-weight:400;
  font-size:20px;
  border: 1px solid #DFDFDF;
  box-sizing:border-box;
  border-right-width:0px;
  background-color:white;
  display:flex;
  flex-direction:column;
  justify-content:center;
  pointer-events: none;
  padding-left:10px;
}

.deactivate-input-wrapper{
  position:relative;
  width:100%;
}

.deactivate-input-icon{
  position:absolute;
  right: 10px;
  top:11px;
  height:15px;
  width:15px;
  pointer-events:none;
}

.deactivate-input {
    -webkit-appearance: none;
}
.deactivate-input::-webkit-inner-spin-button,
.deactivate-input::-webkit-calendar-picker-indicator {
    position:absolute;
    width:100%;
    height:100%;
    opacity:0;
    /*
    display: none;
    -webkit-appearance: none;
    */
}

.deactivate-buttons-container{
  margin-top:30px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  width:100%;
}

.deactivate-button{
  cursor:pointer;
  width:47%;
  display:flex;
  align-items:center;
  justify-content:center;
  height:40px;
  border-radius:40px;
  font-family:Poppins;
  font-weight:600;
  font-size:16px;
}

.deactivate-cancel{
  background-color:#FCFCFC;
  border:1px solid #3440FF;
  color:#3440FF;
}

.deactivate-yes{
  background-color:#3440FF;
  border:1px solid #FCFCFC;
  color:#FCFCFC
}
