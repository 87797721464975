.login-back-header{
  height:80px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:start;
  width:100%;
  box-sizing:border-box;
  font-weight:500;
  font-size:18px;
}

.login-back-button{
  display:flex;
  flex-direction:row;
}

.login-back-button svg{
  height:20px;
  position:relative;
  top:3px;
  margin-right:10px;
}

@media only screen and (max-width: 599px){
  .login-back-header{
    font-size:16px;
  }

  .login-back-button svg{
    height:18px;
  }
}

