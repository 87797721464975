.tab-button {
  border-radius: 6px;
}

.tab-button:focus,
.tab-button:hover {
  outline: none;
  background-color: rgba(214, 46, 112, 0.04);
}

.tab {
  position: relative;
}

.tab::after {
  content: " ";
  width: 0;
  width: 100%;
  transform: scaleX(0);
  display: block;
  transition: transform 0.1s;
  height: 2px;
  background-color: var(--p-pink);
  bottom: 0;
  will-change: transform;
  left: 0;
  position: absolute;
}

.tab--active.tab::after {
  transform: scaleX(1);
}

.mobile-tab{
  margin-right:13px;
  font-size:20px;
}

.tabs-container{
  flex-wrap:wrap;
  width:100%;
}
