.chrome-picker {
  min-width: 100%;
  position: absolute;
  z-index: 10;
}

.brand__colors {
  // flex-basis: 50%;
  display: flex;
  margin-top: 1rem;
}

.brand__color-container {
}

.brand__color-wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  //   flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 25%;
  &:not(:first-child) {
    margin: 0 2rem;
  }
}

.brand__color {
  width: 100%;
  height: 60px;
}

.brand__color-pick {
  border: 2px dashed var(--p-blue);
  display: flex;
  justify-content: center;
  //   padding: 1rem;
  width: 100%;
  height: 100%;
  align-items: center;
  margin-top: 0.5rem;
  max-height: 70px;
}

.preview__container {
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
}

.preview__desktop-container {
  position: relative;
  margin-right: 1rem;
}

.preview__desktop-image {
  width: 41px;
  height: 41px;
  position: absolute;
  top: 25px;
  right: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 800px) {
  .brand__colors {
    flex-basis: 100%;
    flex-direction: column;
  }

  .brand__color-wrapper:not(:first-child) {
    margin: 1rem 0;
  }
}
