.desktop-incoming-orders-container{
  width:100%;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:center;
}

.desktop-no-incoming-orders-container{
  width:100%;
  box-sizing:border-box;
  height:60px;
  background:#f4f4f4;  
  display:flex;
  align-items:center;
  justify-content:center;
  padding:15px;
}

.desktop-incoming-orders-grey-bar{
  width:458px;
  height:60px;
  background:#f4f4f4;  
  position:absolute;
  z-index:-1;
  top:0px;
}

.desktop-no-incoming-orders{
  display:flex;
  align-items:center;
  justify-content:center;
  color:#8C8C8C;
  font-size:18px;
  font-weight:600;
  font-family:Poppins;
  border: 1px dashed #8C8C8C;
  border-radius:4px;
  width:400px;
  height:45px;
  box-sizing:border-box;
}

.desktop-yes-incoming-orders-container-outer{
  width:100%;
  box-sizing:border-box;
  padding:15px;
}

.desktop-yes-incoming-orders-container{
  background: #EAF6ED;
  border: 2px solid #239C44;
  width:100%;
  box-sizing:border-box;
  border-top-right-radius:8px;
  border-top-left-radius:8px;
}

.desktop-yes-incoming-orders-header{
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  background: #239C44;
  border-radius: 4px;
  height:40px;
  font-size:18px;
  font-family:Poppins;
  font-weight:600;
  color:white;
}

.desktop-yes-incoming-orders-container .order-card-scheduled-unconfirmed{
  box-sizing:border-box !important;
  left:0px !important;
}
.desktop-incoming-orders-order-container{
  padding:15px;
}

.desktop-incoming-orders-order-inner-container{
  border-bottom: 1px solid #DFDFDF;
  width:100%;
  height:100%;
}
