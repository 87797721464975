.adjust-buttons-container{
  display:flex;
  width:100%;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  border-radius:3px;
  border: 1px solid #000CCF;
  box-sizing:border-box;
  padding:3px;
}

.adjust-button{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  box-sizing:border-box;
  width:50%;
  font-size:16px;
  font-family:Poppins;
  font-weight:600;
  color:#000CCF;
  background-color:#FCFCFC;
  height:34px;
}

.adjust-button-selected{
  background-color:#000CCF;
  color:#FCFCFC;
}

.adjust-type{
  font-family:Poppins;
  font-weight:600;
  font-size:16px;
  margin-top:20px;
}

.adjust-amount-container{
  width:100%;
}

.adjust-amount-container{
  margin-top:30px;
}

.adjust-amount-label{
  font-family:Poppins;
  font-weight:500;
  font-size:14px;
}

.adjust-amount-input{
  box-sizing:border-box;
  width:100%;
  height:38px;
  border-radius:3px;
  border: 1px solid #DFDFDF;
  font-family:Poppins;
  font-weight:500;
  font-size:18px;
}

.adjust-input-error{
  border-color: #EA5151;
  background-color: #F3EBEB;
}

.adjust-error{
  font-family:Poppins;
  font-size:14px;
  font-weight:600;
  color: #EA5151;
}

.adjust-dropdown-container{
  margin-top:25px;
}

.adjust-dropdown-container select{
  width:100%;
  height:42px;
  border-radius:3px;
  border: 1px solid #DFDFDF;
  font-family:Poppins;
  font-weight:400;
  font-size:18px;
  border: 1px solid #DFDFDF;
  background-color:white;
}

.adjust-reasons-container{
  margin-top:20px;
}

.adjust-reasons-label{
  font-family:Poppins;
  font-weight:600;
  font-size:16px;
}

.adjust-reason-container{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-start;
  padding:7px 0px;
}

.adjust-reason-reason{
  font-family:Poppins;
  font-size:16px;
  font-weight:400;
}

.adjust-reasons-container svg{
  width:18px;
  height:18px;
  margin-right:10px;
}

.adjust-total-container{
  margin-top:15px;
  padding-bottom:20px;
}

.adjust-total-row{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
}

.adjust-total-label{
  font-family:Poppins;
  font-weight:400;
  font-size:18px;
}

.adjust-total-amount{
  font-family:Poppins;
  font-weight:600;
  font-size:18px;
}

