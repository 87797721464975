.Accounting{
  width: 100%;
  max-width: 400px;
  margin: auto;
}

.PaymentsRow{
  transition: all .2s;
}

/* .PaymentsRow:nth-child(odd){
  background: #f6f6f6;
} */

.PaymentsRow:hover{
  background: rgba(66, 132, 244, .2);
  box-shadow: 0px 4px 8px -2px rgba(0,0,0,.2);
  transform: scale(1.01);
  cursor: pointer;
}

.member-orders-table td{
  padding-left:4px ;
  padding-right:4px ;
  font-size:10px ;
}

.member-orders-table th{
  padding-left:4px ;
  padding-right:4px ;
  font-size:10px !important;
}

.member-first-cell{
  padding-left:10px !important;
}

.member-last-cell{
  padding-right:10px !important;
}
