.choose-printer-header *{
  color:#000CCF !important;
  fill:#000CCF !important;
}

.choose-printer-blob{
  background-color:#000CCF;
  width:100%;
  height:300px;
  display:flex;
  justify-content:center;
  align-items:center;
  padding-left:20px;
  padding-right:20px;
  position:relative;
  left:-20px;
  width: calc(100% + 40px);
}

@media only screen and (max-width: 599px){
  .choose-printer-blob{
    height:250px;
  }
}

.choose-printer-blob svg{
  fill:white;
  height:150px;
  width:150px;
}

.choose-printer-title{
  font-family:OPTIChampionBold;
  font-weight:900;
  font-size:20px;
  margin-top:10px;
  margin-bottom:10px;
}


@media only screen and (max-width: 599px){
  .choose-printer-title{
    font-size:18px;
  }

  .choose-printer-blob svg{
    height:120px;
    width:120px;
  }
}

.choose-printer-paragraph{
  font-family:Poppins;
  font-size:16px;
  font-weight:600;
  margin-bottom:15px;
}

@media only screen and (max-width: 599px){
  .choose-printer-paragraph{
    font-size:14px;
  }
}

.choose-printer-big-button{
  width:100%;
  height:40px;
  border-radius:20px;
  display:flex;
  align-items:center;
  justify-content:center;
  font-family:Poppins;
  font-size:16px;
  font-weight:600;
  color:#000CCF;
  border:1px solid #000CCF;
  bottom:30px;
  box-sizing:border-box;
}

.choose-printer-no-printers{
  font-family:Poppins;
  color:red;
  font-weight:500;
  font-size:16px;
  display:flex;
  flex-direction:column;
  align-items:center;
  padding-bottom:20px;
  text-align:center;
}


@media only screen and (max-width: 599px){
  .choose-printer-big-button{
    font-size:14px;
  }

  .choose-printer-no-printers{
    font-size:14px;
  } 
}

.choose-printer-no-printers svg{
  fill:#D14848;
  width:80px;
  height:80px;
  margin-top:15px;
  margin-bottom:15px;
}


@media only screen and (max-width: 599px){
  .choose-printer-no-printers{
    font-size:14px;
  }

  .choose-printer-no-printers svg{
    width:60px;
    height:60px;
  }
}

.choose-printer-printer-container{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  margin-bottom:40px;
}

.choose-printer-connect-button{
  height:40px;
  border-radius:20px;
  display:flex;
  align-items:center;
  justify-content:center;
  font-family:Poppins;
  font-size:16px;
  font-weight:600;
  color:#000CCF;
  border: 2px solid #000CCF;
  padding:0px 10px;
}

.choose-printer-printer-container{
  align-items:flex-start;
  border-bottom:1px solid #8C8C8C;
  padding-bottom:20px;
}


.choose-printer-button-container{
  display:flex;
  flex-direction:column;
  width:49%;
}

@media only screen and (max-width: 599px){


  .choose-printer-connect-button{
    font-size:14px;
    margin-bottom:10px;
    padding:0px 7px;
    height:35px;
  }
  

}

.choose-printer-connnected-printer{
  font-family: OPTIChampionBold;
  font-size: 18px;
  font-weight:900;
}

.choose-printer-sub-setting{
  color: #262525;
  font-family:Poppins;
  font-weight:700;
  font-size:16px;
}

.choose-printer-sub-sub-setting{
  font-family:Poppins;
  font-weight:400;
  font-size:14px;
}


@media only screen and (max-width: 599px){
  .choose-printer-connnected-printer{
    font-size:16px;
  }

  .choose-printer-sub-setting{
    font-size:14px;
  }

  .choose-printer-sub-sub-setting{
    font-size:12px;
  }

}

.choose-printer-settings-button{
  height:40px;
  border-radius:40px;
  display:flex;
  align-items:center;
  justify-content:center;
  padding:0px 15px;
  font-family:Poppins;
  font-size:16px;
  font-weight:600;
  color:#3440FF;
  border:1px solid #3440FF;
} 


@media only screen and (max-width: 599px){
  .choose-printer-settings-button{
    font-size:14px;
  }
}

.choose-printer-disconnect-button{
  margin-top:10px;
  border:1px solid #EA5151;
  color: #EA5151;
  background-color:#F3EBEB;
}

.choose-printer-sub-settig-container{
  padding-top:30px;
  border-bottom:1px solid #F0F0F0;
  padding-bottom:20px;
}
.preferred-printer{
  color:#3440FF;
}

.choose-printer-printer-model{
  max-width:30%;
  font-weight:600p
}

.choose-printer-model-name{
  font-weight:700;
}

@media only screen and (max-width: 599px){
  .choose-printer-printer-model{
      max-width:50%;
  }
}

.desktop-printer-warning{
  text-decoration:italic;
  color:gray;
}

.print-copies-settings{
  display:flex;
  flex-direction:row;
  align-items:center;
  font-family:Poppins;
  font-weight:400;
  font-size:16px;
  margin-top:15px;
}

.copies-input{
  margin-right:10px;
  width:38px;
  height:38px;
  border: 1px solid #DFDFDF;
  border-radius:3px;
  font-family:Poppins;
  font-weight:400;
  font-size:16px;
  box-sizing:border-box;
  padding-left:8px;
}


@media only screen and (max-width: 599px){
  .print-copies-settings{
    font-size:14px;
  }

  .copies-input{
    font-size:14px;
  }

}
.printer-radio-wrapper{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-start;
  padding:5px 0px;
}

.printer-radio-label{
  margin-left:10px;
  position:relative;
  top:2px;
}

@media only screen and (max-width: 599px){
  .printer-radio-label{
    font-size:14px;
  } 

  .connected-test-receipt{
    display:block;
    margin-top:10px;
  }
}
