.section-title{
  font-family:OPTIChampionBold;
  font-weight:900;
  font-size:24px;
  color:var(--captain-black);
}

@media only screen and (max-width: 599px){
  .section-title{
    font-size:18px;
  }
}

.poppins-title{
  font-family:Poppins;
  font-weight:700;
  font-size:20px;
}

.section-title-small{
  font-family:OPTIChampionBold;
  font-weight:900;
  font-size:18px;
  color:var(--captain-black);
}

.section-subtitle{
  font-family:Poppins;
  font-weight:400;
  font-size:18px;
}


@media only screen and (max-width: 599px){
  .section-subtitle{
    font-size:16px;
  }
}

.section-subtitle-margin{
  margin-top:7px;
}

.menu-wrapper{
  background-color:#F4F4F4;
  border-radius:12px;
  box-sizing:border-box;
  width:100%;
  height:64px;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:space-between;
  margin-bottom:20px;
  padding:15px;
}

.semibold-title{
  font-size:18px;
  font-weight:600;
  font-family:Poppins;
  color:var(--captain-black);
}


@media only screen and (max-width: 599px){
  .semibold-title{
    font-size:16px;
  }
}

.regular-font{
  font-size:14px;
  font-weight:400;
  font-family:Poppins;
}

@media only screen and (max-width: 599px){
  .regular-font{
    font-size:12px;
  }
}

.menus-container{
  padding-top:20px;
}

.full-menu-container{
  display:flex;
  flex-direction:row;
  align-items:flex-start;
  width:100%;
  height:100%;
}

.full-menu-left{
  box-sizing:border-box;
  width:250px;
  background-color:var(--background-white);
  height:calc(100vh - 68px - 116px);
  overflow:auto;
  padding-left:20px;
  padding-top:30px;
  display:flex;
  flex-direction:column;
  position:sticky;
  top:128px;
}

.menu-items-container{
  box-sizing:border-box;
  width:calc(100% - 250px);
  padding:20px;
  padding-bottom:50px;
}

@media only screen and (max-width: 599px){
  .menu-items-container{
    width:100%;
    padding:20px;
  }
}


@media only screen and (min-width: 1000px){
  .menu-items-container{
    box-sizing:border-box;
    width:100%;
    padding-left:35px;
    padding-right:20px;
    padding-bottom:50px;
    padding-top:0px;
  }
}

.desktop-menu-box-row{
  display:flex;
  flex-direction:row;
  width:100%;
  align-items:flex-start;
  justify-content:space-between;
}

.menu-items-list-mobile{
  box-sizing:border-box;
  width:100%;
  padding-left:20px;
  padding-right:20px;
  display:flex;
  flex-direction:column;
  padding-top:15px;
}

.menu-sections-list-container{
  width:100%;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:flex-start;
  padding-top:10px;
}

.menu-sections-list-container-mobile{
  width:100%;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:flex-start;
  padding-top:10px;
}


.menu-section-box{
  cursor:pointer;
  margin-bottom:20px;
  box-sizing:border-box;
  background-color:#F4F4F4;
  width: calc(100% - 40px);
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:space-between;
  padding:10px;
  border-radius:12px;
}


@media only screen and (max-width: 599px){
  .menu-section-box{
    width:100%;
  }
}

.menu-box-title{
  font-size:20px;
  font-family:Poppins;
  font-weight:600;
  color:var(--pure-black);
}

@media only screen and (max-width: 599px){
  .menu-box-title{
    font-size:16px;
  }
}

.menu-box-subtitle{
  color:var(--helper-text);
  font-size:14px;
  font-weight:400;
  font-family:Poppins;
}

@media only screen and (max-width: 599px){
  .menu-box-subtitle{
    font-size:12px;
  }
}

.menu-section-title{
  font-size:22px;
  font-weight:700;
  font-family:Poppins;
  color:var(--pure-black);
  margin-bottom:15px;
  position:relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}


@media only screen and (max-width: 599px){
  .menu-section-title{
    font-size:18px;
  }
}

.menu-section-description{
  font-size:20px;
  font-weight:400;
  font-family:Poppins;
  color:var(--pure-black);
  padding-bottom:30px;
}


@media only screen and (max-width: 599px){
  .menu-section-description{
    font-size:16px;
  }
}

.menu-item-box{
  cursor:pointer;
  background-color:#F4F4F4;
  border-radius:12px;
  box-sizing:border-box;
  width:100%;
  min-height:80px;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:space-between;
  margin-bottom:20px;
  padding:8px 15px;
  position:relative;
}


@media only screen and (min-width: 1000px){
  .desktop-menu-box-row .menu-item-box{
  }
}

.menu-item-box-bottom-row{
  width:100%;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
}

.menu-item-deactivated-until{
  font-family:Poppins;
  font-weight:600;
  font-size:11px;
  color:#000CCF;
}

.menu-item-box-deactivated{
  background-color:#DFDFDF !important;
}



@media only screen and (min-width: 1000px){
   .desktop-menu-box-row  .menu-item-box-expanded{
  }
}


.menu-item-box-expanded{
  box-sizing:border-box;
  width:100%;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  margin-bottom:20px;
  position:relative;
}

.menu-item-box-expanded-left{
  box-sizing:border-box;
  padding:8px 15px;
  width:calc(100% - 85px);
  background-color: #F4F4F4;
  /*border-bottom-left-radius:12px;
  border-top-left-radius:12px;*/
  border-radius:12px;
  min-height:80px;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:space-between;
 
}

.menu-item-name{
  max-width:calc(100% - 30px);
  font-family:Poppins;
  font-weight:500;
  font-size:20px;
}


@media only screen and (max-width: 599px){
  .menu-item-name{
    font-size:16px;
  }

  .menu-item-box{
    min-height:68px;
  }
}

.menu-item-price{
  font-family:Poppins;
  font-weight:500;
  font-size:14px;
  padding:3px 6px;
  border-radius:12px;
  background-color: rgba(252, 252, 252, 0.8);
}


@media only screen and (max-width: 599px){
  .menu-item-price{
    font-size:12px;
  }
}

.menu-item-elipses{
  cursor:pointer;
  font-family:Poppins;
  font-weight:900;
  font-size:15px;
  position:absolute;
  top:0px;
  right:0px;
  padding:8px;
}

.menu-option-container{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  width:85px;
}

.menu-option-label{
  font-size:13px;
  font-weight:400;
  font-family:Poppins;
  color:#000CCF;
}

.delete-x-container{
  width:44px;
  height:44px;
  border-radius:44px;
  display:flex;
  align-items:center;
  justify-content:center;
}

.delete-x-container svg{
  width:50px;
  height:50px;
}

.deactivate-x-container{
  width:44px;
  height:44px;
  border-radius:44px;
  display:flex;
  align-items:center;
  justify-content:center;
  background-color:#000CCF;
}

.deactivate-x-container svg{
  width:30px;
  height:30px;
  fill:#FCFCFC;
}

.archive-x-container{
  width:44px;
  height:44px;
  border-radius:44px;
  display:flex;
  align-items:center;
  justify-content:center;
  background-color:purple;
}

.archive-x-container svg{
  width:25px;
  height:25px;
  fill:#FCFCFC;
}

.reactivate-x-container{
  width:44px;
  height:44px;
  border-radius:44px;
  display:flex;
  align-items:center;
  justify-content:center;
  background-color:#239C44;
}

.reactivate-x-container svg{
  width:30px;
  height:30px;
}


.deactivate-x-container *{
  fill:var(--pure-white);
}

.reactivate-x-container *{
  fill:var(--pure-white);
}

.menu-section-hr{
  width:calc(100% - 40px);
  height:1px;
  background-color:#DFDFDF;
  margin-bottom:20px;
}

.menu-tabs{
  display:flex;
  width:100%;
  flex-direction:row;
  background-color:#F4F4F4;
  position:sticky;
  top:68px;
  z-index:5;
}

@media only screen and (max-width: 599px){
  .menu-tabs{
    top:calc(56px + var(--inset-top));
  }
}

.menu-tab{
  box-sizing:border-box;
  width:50%;
  font-weight:600;
  font-size:22px;
  font-family:Poppins;
  display:flex;
  align-items:center;
  justify-content:center;
  height:60px;
  color:#595959;
}

@media only screen and (max-width: 599px){
  .menu-tab{
    font-size:16px;
    height:42px;
  }
}

.menu-tab-selected{
  color:#0B1246;
  font-weight:900;
  border-bottom:3px solid #0B1246;
}

.menu-section-container{
  margin-bottom:50px;
}

.full-menu-mobile{
  display:flex;
  flex-direction:column;
  padding-bottom:40px;
  box-sizing:border-box;
  width:100%;
  box-sizing:border-box;
  padding:20px;
  padding-bottom:50px;
}


.modifier-classes-page{
  width:100%;
  box-sizing:border-box;
  padding:10px 20px 0px 20px;
}

.menu-box-internal-name{
  color:#595959;
  font-size:16px;
  font-weight:600;
}

@media only screen and (max-width: 599px){
  .menu-box-internal-name{
    font-size:14px;
  }
}

.modifier-class-options-circle{
  border-radius:8px;
  display:flex;
  align-items:center;
  justify-content:center;
  color:#262525;
  font-size:14px;
  background: #DFDFDF;
  padding:3px 6px;
  font-weight:600;
}

.modifier-class-option-search-result-item{
  border-radius:8px;
  display:flex;
  align-items:center;
  justify-content:center;
  color:#000CCF;
  font-size:14px;
  background: #DFDFDF;
  padding:3px 6px;
  font-weight:600;
  margin-left:10px;
}

.modifier-class-options-circle-deactivated{
  margin-left:15px;
  border-radius:8px;
  display:flex;
  align-items:center;
  justify-content:center;
  background:#DFDFDF ;
  font-size:14px;
  padding:3px 6px;
  font-weight:600;
}

@media only screen and (max-width: 599px){
  .menu-box-internal-name{
    font-size:14px;
  }
}

.menu-item-box-with-internal-name{
  min-height:100px;
}

.menu-page-paddiing-top{
  margin-top:20px;
}

.modifier-page-title-row{
  display:flex;
  align-items:center;
  justify-content:space-between;
}


.modifier-class-add-modifier{
  font-weight:600;
  font-size:16px;
}

@media only screen and (max-width: 599px){
  .modifier-class-add-modifier{
    font-size:14px;
  }
}

.menu-item-modifier-groups-title-row{
  margin-top:35px;
  margin-bottom:15px;
  display:flex;
  align-items:center;
  justify-content:space-between;
}

.menu-item-modifier-group-list{
  padding-left:20px;
  padding-right:20px;
  padding-bottom:calc(56px + 10px +  var(--inset-bottom));
}


.menu-section-add-section-wrapper{
  position: fixed;
  top: 127px;
  right: 0px;
  z-index: 11; 
  background-color: var(--pure-white);
  width: calc(100% - 250px);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content:flex-end;
}

@media only screen and (max-width: 599px){
  .menu-section-add-section-wrapper{
    top:98px;
    width:100%;
  }
}

.menu-section-add-section-header{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  /* position: sticky; */
  /* z-index: 999; */
  top: 0px;
  color: #3440FF;
  font-size:16px;
  font-weight:600;
  padding-right:15px;
  cursor:pointer;
}


.menu-section-add-section-header svg{
  fill: #3440FF;
  margin-right:8px;
  height:15px;
  width:15px;
}

.sub-modifier-classes-container{
  width:calc(100% - 50px);
}

.sub-modifier-class{
  background-color:var(--pure-white);
  width:80%;
}

.inside-box{
  background-color:var(--pure-white) !important;
}

.desktop-sections-container{
  box-sizing:border-box;
  height: 100vh;
  width:236px;
  position:sticky;
  top:0px;
  overflow-y:auto;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  padding:20px;
  padding-top:70px;
}

.desktop-menu-sections-title{
  color:262525;
  font-size:20px;
  font-weight:600;
  margin-bottom:20px;
}

.menu-section-box-desktop{
  display:flex;
  flex-direction:column;
  flex-direction:space-between;
  min-height:76px;
  cursor:pointer;
}

.menu-section-box-title-desktop{
  font-size:500;
  font-weight:18px;
  color:#262525;
}

.menu-section-box-subtitle-desktop{
  font-size:14px;
  font-weight:400;
  color:#595959;
}

.menu-title-desktop{
  font-size:24px;
  font-weight:600;
  color:#262525;
}

.desktop-menu-top-row{
  position:sticky;
  z-index:5;
  background-color:white;
  top:50px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  width:100%;
  padding:20px;
  padding-left:35px;
  box-sizing:border-box;
}

.menu-title-desktop-button{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  color:#000CCF;
  font-size:16px;
  font-weight:600;
  border-radius:40px;
  height:40px;
  padding:0px 15px;
  background: #FCFCFC;
  border: 1px solid #000CCF;
  cursor:pointer;
}

.menu-title-desktop-button svg{
  height:15px;
  width:15px;
  fill:#000CCF;
  margin-right:8px;
}

.disabled-save-button{
  background-color:#DFDFDF !important;
  border:0px;
  color:var(--pure-white) !important;
}

.disabled-save-button svg{
  fill:var(--pure-white) !important;
}

.menu-item-box-appears-in{
  color: #595959;
  font-family:Poppins;
  font-size:14px;
  font-weight:400;
  margin-top:10px;
  margin-bottom:10px;
}

