.fixed-footer{
  position:fixed;
  bottom:0px;
  z-index:2;
  width:100%;
  height:calc(56px + var(--inset-bottom));
  background-color:#FCFCFC;
}

.fixed-footer-top{
  width:100%;
  height:56px;
  display:flex;
  flex-direction:row;
  align-items:center;
}

.fixed-footer-bottom{
  width:100%;
  height:200px;
}

.fixed-footer-section{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  box-sizing:border-box;
  flex: 1 1 auto;
  border-top:2px solid #DFDFDF;
  font-family:Poppins;
  font-weight:400;
  font-size:12px;
  height:54px;
  position:relative;
}


.fixed-footer-section svg{
  height:15px;
  width:15px;
}

.fixed-footer-section-selected{
  pointer-events:none;
  border-top:2px solid #3440FF;
} 


.fixed-footer-section-selected *{
  color:#3440FF !important;
  fill:#3440FF !important;
}

.footer-order-alert{
  position:absolute;
  width:19px;
  height:19px;
  border-radius:40px;
  display:flex;
  align-items:center;
  justify-content:center;
  background-color:#EA5151;
  color:#FCFCFC !important;
  font-family:Poppins;
  font-weight:600;
  font-size:12px;
  left: 54%;
  top: 3px;
}
