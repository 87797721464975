.login-member-container{
  width:100%;
  height:100%;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
}

.login-member-container-left{
  width:590px;
  height:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  background: #FCFCFC;
}


.login-member-container-left-inner{
  width:400px;
  min-height:400px;
  box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.176);
  padding:35px;
  border-radius: 24px;
  background: #FCFCFC;
}

.login-member-container-right{
  width:calc(100vw - 585px);
}

.login-member-right-img{
  width:100%;
  height:100%;
  object-fit: cover;

}

.login-with-member-welcome-back{
  font-family:Poppins;
  font-weight:600;
  color:#262525;
  font-size:30px;
  margin-top:20px;
}

.login-with-member-subheader{
  font-family:Poppins;
  font-weight:400;
  font-size:18px;
  color:#919397;
}

.login-with-member-input-label{
  font-family:Poppins;
  font-weight:500;
  font-size:14px;
  color:#262525
}

.login-with-member-input{
  width:100%;
  background: #FFFFFF;
  border: 1px solid #DFDFDF;
  box-sizing: border-box;
  border-radius: 3px;
  height:38px;
}

.login-with-member-signin{
  background-color:#0B1246;
  display:flex;
  color:white;
  align-items:center;
  justify-content:center;
  width:100%;
  height:40px;
  border-radius:40px;
  margin-top:20px;
  cursor:pointer;
}

.login-with-member-or-container{
  width:100%;
  display:flex;
  align-items:center;
  justify-content:space-between;
  font-family:Poppins;
  font-size:14px;
  color:#919397;
  margin-top:25px;
}

.login-with-member-or-line{
  width:calc(50% - 20px);
  height:1px;
  background-color:#919397;
}

.login-with-phone{
  width:100%;
  height:40px;
  border-radius:40px;
  font-family:Poppins;
  font-size:16px;
  font-weight:500;
  color:#595959;
  display:flex;
  align-items:center;
  justify-content:center;
  box-sizing:border-box;
  border: 1px solid #595959;
  margin-top:25px;
  cursor:pointer;
}

.login-with-google{
  width:100%;
  height:40px;
  border-radius:40px;
  font-family:Poppins;
  font-size:16px;
  font-weight:500;
  color:#595959;
  background-color:#007bff;
  display:flex;
  align-items:center;
  justify-content:center;
  box-sizing:border-box;
  margin-top:25px;
  cursor:pointer;
  color:white;
}

.login-with-google svg{
  height:16px;
  width:16px;
  margin-right:15px;
}

.login-with-google *{
  fill:white;
}
