.modifier-modal-confirm-container{
  width:416px;
  max-width:90vw;
  border-radius:40px;
  background-color:var(--pure-white);
  padding:30px;
  display:flex;
  flex-direction:column;
}

.modifier-modal-confirm-title{
  font-size:24px;
  color:#262525d;
  font-family:OPTIChampionBold;
  font-weight:900;
}

.modifier-modal-confirm-text{
  font-size:20px;
  font-weight:400;
  margin-top:20px;
  margin-bottom:30px;
}


.modifier-confirm-modal-button-row{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  margin-top:35px;
}

.modifier-confirm-modal-button{
  height:40px;
  box-sizing:border-box;
  width:calc(50% - 30px);
  margin-right:15px;
  margin-left:15px;
  font-weight:600;
  font-size:16px;
  border-radius:40px;
  display:flex;
  align-items:center;
  justify-content:center;
}

.modifier-confirm-modal-button-cancel{
  background-color:#FCFCFC;
  border:1px solid #3440FF;
  color:#3440FF;
}

.modifier-confirm-modal-button-save{
  background-color:#3440FF;
  color:#FCFCFC;
}

.confirm-modal-adjustments-to{
  font-weight:400;
  font-size:16px;
  display:flex;
  flex-direction:row;
  align-items:center;
  margin-top:30px;
  padding:10px 0px;
  cursor:pointer;
}

.confirm-modal-adjustments-to svg{
  height:14px;
  width:14px;
  margin-left:5px;
}

.confirm-modal-items{
  margin-top:10px;
  max-height:40vh;
  overflow-y:auto;
}

.confirm-modal-item{
  font-weight:400;
  font-size:16px;
}

@media only screen and (max-width: 599px){
  .modifier-modal-confirm-title{
    font-size:22px;
  }

  .modifier-modal-confirm-text{
    font-size:18px;
  }

  .modifier-confirm-modal-button{
    font-size:14px;
  }

  .confirm-modal-adjustments-to{
    font-size:14px;
  }

  .confirm-modal-item{
    font-size:14px;
  }

}

