$transition: 200ms linear;

.toggle-label {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.toggle-label > input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  border-radius: 100px;
  transition: $transition;
  border: 1px solid var(--p-blue);
}
.toggle-switch--checked {
  background-color: var(--p-blue);
}

.toggle-switch::before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  border-radius: 50%;

  transform: translateX(-50%);
  background-color: var(--p-blue);

  transition: $transition;
  -webkit-transition: $transition;
}

.toggle-switch--checked::before {
  background-color: white;
  transform: translateX(50%);
}
