.breadcrumbs-container{
  width:calc(100% - 236px);
  height:50px;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
  box-sizing:border-box;
  border-bottom: 3px solid #F0F0F0;
  padding-left:15px;
  position:fixed;
  top:0px;
  z-index:4;
  background-color:var(--pure-white);
}

.breadcrumbs-inner-container{
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items:center;
}

.breadcrumb{
  font-weight:400;
  font-size:16px;
  color:#919397;
  margin-left:5px;
}

.breadcrumb-current{
  font-weight:400;
  font-size:16px;
  color:#000CCF;
  margin-left:5px;
}

.breadcrumbs-search{
  margin-right:20px;
  cursor:pointer;
}


.breadcrumbs-search:hover{
  fill:blue;
}
