.desktop-tabs-container{
  display:flex;
  align-items:center;
  width:100%;
  box-sizing:border-box;
  height:60px;
  background:#f4f4f4;
  position:sticky;
  top:0px;
  z-index:9;
}

.desktop-tab{
  width:33%;
  font-size:20px;
  font-family:Poppins;
  font-weight:600;
  color:#0B1246;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  box-sizing:border-box;
  height:100%;
}

.desktop-tab-inner{
  box-sizing:border-box;
  height:100%;
  width:calc(100% - 50px);
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
}

.desktop-tab-selected{
  border-bottom: 3px solid #0B1246;
}

.desktop-tab-circle-count{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  font-size:18px;
  font-weight:600;
  font-family:Poppins;
  color:#FCFCFC;
  background-color:#0B1246;
  width:28px;
  height:31px;
  border-radius:14px;
  margin-left:6px;
}

.desktop-tab-history-svg{
  height:24px;
  width:24px;
  margin-left:8px;
}
