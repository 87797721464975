.desktop-orders-page-container{
  width:100%;
  display:flex;
  flex-direction:row;
  align-items:flex-start;
}

.desktop-orders-container-container{
  width:calc(100% - 236px - 458px);
  display:flex;
  flex-direction:column;
}

.desktop-orders-incoming-orders-container{
  width:458px;
  display:flex;
  flex-direction:column;
  position:fixed;
  top:0px;
  right:0px;
  overflow-y:auto;
  box-sizing:border-box;
  height:100vh;
  overflow-x:hidden;
}

@media only screen and (min-width:1000px){
  .desktop-orders-content .search-bar-container-sticky{
    top:9px !important;
  }
}
