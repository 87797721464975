.modifier-class-edit-title{
  font-size:20px;
  font-weight:700;
  color:#262525;
  margin-bottom: 18px;
}

@media only screen and (max-width: 599px){
  .modifier-class-edit-title{
    font-size:18px;
  }
}

.modifier-class-edit-hr{
  background-color:#8C8C8C;
  width:100%;
  height:1px;
  margin-top:15px;
  margin-bottom:15px;
}

.modifier-class-price-override-row{
  height:40px;
  display:flex;
  align-items:center;
}

.modifier-class-price-override-row-left{
  display:flex;
  flex-direction:row;
  align-items:center;
}

.modifier-class-price-override-row-left .checkbox{
  margin-right:8px;
}

.modifier-class-edit-modifier-row{
  padding:10px 0px;
  font-weight:400;
  font-size:16px;
  margin-top:5px;
  margin-bottom:5px;
  display:flex;
  align-items:center;
}


.modifier-class-edit-modifier-row-mobile{
  width:calc(100% - 40px);
  box-sizing:border-box;
  padding:10px 0px;
  font-weight:400;
  font-size:14px;
  margin-top:5px;
  margin-bottom:5px;
  display:flex;
  align-items:center;
}

@media only screen and (max-width: 599px){
  .modifier-class-edit-modifier-row{
    font-size:14px;
  }
}

.modifier-gap{
  width:100%;
  height:10px;
  background:pink;
}

.modifier-class-edit-dragged{
  opacity:0;
}



.mobile-bottom-border{
  position:absolute;
  bottom:0px;
  left:-60px;
  width:200vw;
  height:2px;
  background:green;
}


.mobile-top-border{
  position:absolute;
  top:0px;
  left:-60px;
  width:200vw;
  height:2px;
  background:green;
}

.grid-bottom-border{
  position:absolute;
  bottom:0px;
  left:-40px;
  width:250vw;
  height:2px;
  background:green;
}


.grid-top-border{
  position:absolute;
  top:0px;
  left:-40px;
  width:250vw;
  height:2px;
  background:green;
}

.modifier-class-edit-hovered-top{
  border-top:2px solid green;
}

.modifier-class-edit-hovered-bottom{
  border-bottom:2px solid green;
}

.grid-class-edit-hovered-top{
  border-top:2px solid green;
}

.grid-class-edit-hovered-bottom{
  border-bottom:2px solid green;
}

.modifier-dragged{
  opacity:0.5;
  text-align:center;
  padding-left:80px;
  width: calc(100% - 130px);
  background-color: #FCFCFC;
  height: 40px;
  border: 1px solid #DFDFDF;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;

}

.modifier-class-edit-modifier-row svg{
  width:10px;
  height:10px;
  margin-right:10px;

}

.modifier-class-edit-name{
  box-sizing:border-box;
  width: calc(100% - 180px);
  background-color:#FCFCFC;
  height:40px;
  border: 1px solid #DFDFDF;
  border-radius:4px;
  padding-left:7px;
  display:flex;
  align-items:center;
  font-weight:400;
  font-size:16px;
}

.modifier-class-edit-price{
  box-sizing:border-box;
  width:80px;
  margin-left:20px;
  background-color:#FCFCFC;
  height:40px;
  border: 1px solid #DFDFDF;
  border-radius:4px;
  padding-left:7px;
}

.modifier-class-edit-footer-container{
  width:100%;
  bottom:calc(56px + var(--inset-bottom) + 20px);
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modifier-class-button{
  box-sizing:border-box;
  height:40px;
  border-radius:40px;
  width:45%;
  display:flex;
  margin-right:10px;
  margin-left:10px;
  align-items:center;
  justify-content:center;
  background-color:#FCFCFC;
  font-weight:600;
  font-size:16px;
  color:#3440FF;
  border:1px solid #3440FF;
}

.modifier-class-button-save-disabled{
  background-color:#DFDFDF;
  pointer-events:none;
  color:#8C8C8C;
  border-width:0px;
}

.modifier-class-button-save-enabled{
  background-color:#3440FF;
  color:#FCFCFC;
  border-width:0px;
}


@media only screen and (max-width: 599px){
  .modifier-class-button{
    font-size:14px;
  }
}

.modifier-class-edit-price-override{
  box-sizing:border-box;
  width:80px;
  margin-left:20px;
  background-color:#FCFCFC;
  height:40px;
  border: 1px solid #DFDFDF;
  border-radius:4px;
  padding-left:7px;
  font-weight:400;
  font-size:16px;
}

@media only screen and (max-width: 599px){
  .modifier-class-edit-price-override{
    font-size:14px;
  }
}

.modifier-class-edit-question-title{
  font-size:16px;
  font-weight:600;
  margin-bottom:9px;
}


.modifier-class-radio-wrapper{
  display:flex;
  flex-direction:row;
  align-items:flex-start;
  font-size:16px;
  padding-bottom:5px;
}

.modifier-class-radio-wrapper input{
  position:relative;
  top:1px;
  margin-right:5px;
}


@media only screen and (max-width: 599px){
  .modifier-class-edit-question-title{
    font-size:14px;
  }

  .modifier-class-radio-wrapper{
    font-size:14px;
  }
}
.modifier-class-edit-page{
  padding-bottom: calc(56px + var(--inset-bottom) + 50px) !important;
}

.modifier-class-radio-indented{
  position:relative;
  left:20px;
}


.max-modifiers-input{
  box-sizing:border-box;
  width:40px;
  margin-left:20px;
  background-color:#FCFCFC;
  height:20px;
  border: 1px solid #DFDFDF;
  border-radius:4px;
  padding-left:7px;
  font-weight:400;
  font-size:16px;
  margin-left:10px;
}

@media only screen and (max-width: 599px){
  .max-modifiers-input{
    font-size:14px;
  }
}
  
.modifier-class-modifier-buttons-container{
  display:flex;
  flex-direction:row;
  width:100%;
  margin-top:20px;
  margin-bottom:20px;
  align-items:center;
  justify-content:center;
}

.modifier-class-modifier-button{
  border-radius:40px;
  height:40px;
  display:flex;
  align-items:center;
  justify-content:center;
  font-weight:400;
  font-size:16px;
  color:#000CCF;
  background: #FCFCFC;
  border: 1px solid #3440FF;
  box-sizing: border-box;
  width:45%;
}


.modifier-class-modifier-button svg{
  fill:#000CCF;
  height:16px;
  width:16px;
  margin-right:9px;
}

@media only screen and (max-width: 599px){
  .modifier-class-modifier-button{
    font-size:14px;
  }
}

.modifier-delete-svg{
  width:24px !important;
  height:24px !important;
  margin-left:10px !important;
  margin-right:0px !important;
}

.modifier-class-yellow-blurb{
  padding:13px;
  width:100%;
  box-sizing:border-box;
  display:flex;
  flex-direction:column;
  background: #FFFAEB;
  border: 2px solid #F0CF58;
  margin-top:15px;
}

.modifier-class-yellow-blurb-top-row{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-start;
  color:#0B1246;
  font-weight:700;
  font-size:16px;
  margin-bottom:10px;
}

.modifier-class-yellow-blurb-top-row svg{
  fill:#0B1246;
  height:14px;
  width:14px;
  margin-right:5px;
}

.modifier-class-yellow-blurb-bottom{
  font-weight:500;
  font-size:14px;
}

@media only screen and (max-width: 599px){
  .modifier-class-yellow-blurb-top-row{
    font-size:14px;
  }

  .modifier-class-yellow-blurb-bottom{
    font-size:12px;
  }
}

.desktop-modifier-class-edit-title-container{
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:space-between;
}

.desktop-modifier-class-title{
  font-size:24px;
  font-family:OPTIChampionBold;
}


@media only screen and (min-width: 1000px){
  .modifier-class-edit-content-container{
    display:flex;
    align-items:flex-start;
    justify-content:space-between;
    width:100%;
  }

  .modfifier-class-edit-left-side{
    width: calc(100% - 450px);
    padding-right: 35px;
  }

  .modifier-class-edit-min-max-container{
    width:500px;
  }
}

.desktop-modifier-class-internal-name{
  font-size:22px;
  font-weight:700;
  color:#595959;
}
