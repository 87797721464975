.cart-first-row{
  padding-bottom:10px;
  background-color: #FCFCFC;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  left: -20px;
  position: relative;
}

.cart-first-row-inner{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  border-bottom: 1px solid #8C8C8C;
}

.cart-num-items{
  font-family:OPTIChampionBold;
  font-weight:900;
  font-size:20px;
}

@media only screen and (max-width: 599px){
  .cart-num-items{
    font-size:18px;
  }
}

.cart-order-hash{
  font-family:Poppins;
  font-weight:400;
  font-size:20px;
}

@media only screen and (max-width: 599px){
  .cart-order-hash{
    font-size:18px;
  }
}

.cart-items-container{
  background-color: #FCFCFC;
}

.cart-item-container{
  align-items:center;
  justify-content:space-between;
  background-color: #FCFCFC;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  left: -20px;
  position: relative;
}

.cart-item-inner-container{
  border-bottom:1px solid #DFDFDF;
  padding: 10px 0px;
}

.cart-item-inner-container-last-item{
  border-width:0px;
}

.cart-item-quantity{
  font-family:OPTIChampionBold;
  font-weight:900;
  font-size:20px;
  display:inline-block;
  vertical-align:middle;
  box-sizing:border-box;
  padding: 0px 10px;
}


@media only screen and (max-width: 599px){
  .cart-item-quantity{
    font-size:18px;
  }
}

.cart-item-quantity-multiple{
  border:1px solid #0B1246;
  border-radius:2px;
}

.cart-item-name{
  font-family:Poppins;
  font-size:16px;
  font-weight:600;
  margin-left:10px;
  display:inline-block;
  vertical-align:middle;
  position:relative;
  top:1px;
}


@media only screen and (max-width: 599px){
  .cart-item-name{
    font-size:14px;
  }
}

.cart-item-quantity-and-name-container{
  max-width: calc(100% - 60px);
}

.cart-item-price{
  font-family:Poppins;
  font-weight:400;
  font-size:16px;
  display:inline-block;
  vertical-align:middle;
}


@media only screen and (max-width: 599px){
  .cart-item-price{
    font-size:14px;
  }
}

.cart-item-modifier{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  font-family:Poppins;
  font-weight:500;
  font-size:14px;
}


@media only screen and (max-width: 599px){
  .cart-item-modifier{
    font-size:12px;
  }
}

.cart-item-special-instructions{
  background-color: #FFFAEB;
  color: #0B1246;
  border: 2px solid #E1B310;
  border-radius:4px;
  box-sizing:border-box;
  width:100%;
  position:relative;
  font-family:Poppins;
  font-size:14px;
  font-weight:500;
  padding-top:5px;
  padding-bottom:5px;
  padding-left:30px;
  padding-right:7px;
  margin-top:13px;
  margin-bottom:13px;
}


@media only screen and (max-width: 599px){
  .cart-item-special-instructions{
    font-size:12px;
  }
}

.special-instructions-warning{
  position:absolute; 
  top:4px;
  left:5px;
  height:20px;
  width:20px;
}

.cart-item-modifier-container{
  margin-top: 10px;
  padding-left: 30px;
}

.cart-finance-container{
  background-color:#F0F0F0;
  width:100%;
  padding-top:20px;
  padding-bottom:20px;
  padding-left:20px;
  padding-right:20px;
  position:relative;
  left:-20px;
}

.cart-finance-row{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  padding:4px 0px;
}

.cart-finance-row-left{
  font-family:Poppins;
  font-size:16px;
  font-weight:600;
}

.cart-finance-row-right{
  font-family:Poppins;
  font-size:16px;
  font-weight:400;
}


@media only screen and (max-width: 599px){
  .cart-finance-row-left{
    font-size:14px;
  }

  .cart-finance-row-right{
    font-size:14px;
  }
}

.total-row{
  padding-top:15px;
}

.total-row div{
  font-size:20px !important;
}

.cart-paid{
  font-family:Poppins;
  font-weight:700;
  font-size:16px;
  color:#0B1246;
  padding:10px 0px;
  border-top: 1px solid #0B1246;
  border-bottom: 1px solid #0B1246;
  width:100%;
  text-align:center;
  margin-top:20px;
  margin-bottom:30px;
}


@media only screen and (max-width: 599px){
  .total-row div{
    font-size:18px !important;
  }

  .cart-paid{
    font-size:14px;
  }
}

.error-loading-order{
  color:var(--error-red);
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
}

.cart-utensils-row{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  padding-bottom:20px;
  padding-top:15px;
}

.cart-utensils-left{
  display:flex;
  flex-direction:row;
}

.utensils-yes-no{
  font-family:OPTIChampionBold;
  font-weight:900;
  font-size:18px;
  display:inline-block;
  vertical-align:middle;
  box-sizing:border-box;
}

.cart-utensils-row svg{
  height:28px;
  width:28px;
}

@media only screen and (max-width: 599px){
  .utensils-yes-no{
    font-size:16px;
  }

  .cart-utensils-row svg{
    height:24px;
    width:24px;
  }
}

