.stripe-reader-image-container{
  width:100%;
  background-color:#f5f9fc;
}

.stripe-reader-big-image{
  width:100vw;
  height:100vw;
  max-height:40vh;
}

.connect-to-terminal-title{
  font-family:Poppins;
  font-size:24px;
  font-weight:600;
  color:#262525;
  margin-top:25px;
}

.connect-to-terminal-blurb{
  margin-top:15px;
  font-family:Poppins;
  font-weight:500;
}

.connect-to-terminal-button{
  display:flex;
  align-items:center;
  justify-content:center;
  background-color:#3440FF;
  color:#FCFCFC;
  width:100%;
  font-size:16px;
  font-family:Poppins;
  font-weight:600;
  height:40px;
  border-radius:40px;
  margin-top:25px;
}
