.menu-item-deactivated-banner{
  width:100%;
  box-sizing:border-box;
  height:60px;
  background-color:#F0CF58;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-start;
  color:var(--ink-primary);
  font-size:18px;
  font-weight:600;
  font-family:Poppins;
  padding-left:20px;
}


@media only screen and (max-width: 599px){
  .menu-item-deactivated-banner{
    font-size:16px;
  }
}


.menu-item-deactivated-banner svg{
  height:14px;
  width:14px;
  fill:var(--ink-primary);
  margin-right:10px;
}


.@media only screen and (max-width: 599px){
  .menu-item-deactivated-banner{
    font-size:16px;
  }
  
  .menu-item-deactivated-banner svg{
    height:12px;
    width:12px;
  }

}

.menu-item-page-top-button-container{
  padding:15px 0px;
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing:border-box
}

.menu-item-page-top-button{
  height:44px;
  width:328px;
  display:flex;
  align-items:center;
  justify-content:center;
  font-family:Poppins;
  font-size:16px;
  font-weight:600;
  border-radius:40px;
}


.menu-item-page-top-button svg{
  width:30px;
  height:30px;
  margin-right:5px;
}

@media only screen and (max-width: 599px){
  .menu-item-page-top-button{
    font-size:14px !important;
  }

  .menu-item-page-top-button svg{
    width:26px;
    height:26px;
  }

}




.menu-item-page-top-button-reactivate{
  border: 1px solid #239C44;
  background-color: #FCFCFC;
  color:#239C44;
}

.menu-item-page-top-button-reactivate *{
  fill:#239C44;
}


.menu-item-page-top-button-deactivate{
  border: 1px solid #0B1246;
  background-color: #F4F4FB; 
  color:#0B1246;
}

.menu-item-page-top-button-deactivate *{
  fill:#0B1246;
}

.menu-item-blurb-top-row{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-start;
}

.menu-item-blurb-image img{
  height:120px;
  margin-right:20px;
}

.menu-item-blurb-item-info{
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:flex-start;
}

.menu-item-blurb-name{
  font-size:24px;
  font-weight:700;
  margin-bottom:10px;
}

@media only screen and (max-width: 599px){
  .menu-item-blurb-name{
    font-size:20px;
  }
}

.menu-item-blurb-category{
  font-weight:600;
  font-size:16px;
}

.menu-item-blurb-price{
  font-weight:500;
  font-size:16px;
}


@media only screen and (max-width: 599px){
  .menu-item-blurb-category{
    font-size:14px;
  }

  .menu-item-blurb-price{
    font-size:14px;
  }
}

.menu-item-blurb-container{
  padding:20px;
  background-color:#F4F4FB;
  position:relative;
}

.menu-item-blurb-blurb{
  font-weight:400;
  font-size:16px;
  margin-top:30px;
  padding-bottom:30px;
}


@media only screen and (max-width: 599px){
  .menu-item-blurb-blurb{
    font-size:14px;
  }
}

.menu-item-edit-button{
  position:absolute;
  width:120px;
  height:40px;
  width:156px;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  color:#000CCF;
  background-color:var(--pure-white);
  border:1px solid #000CCF;
  font-size:16px;
  font-weight:600;
  border-radius:40px;
  bottom: -20px;
  left: calc(50% - 78px);
}


@media only screen and (max-width: 599px){
  .menu-item-edit-button{
    font-size:15px;
  }
}

.menu-item-edit-button svg{
  fill:#000CCF;
  height:20px;
  width:20px;
  margin-right:7px;
}

.menu-item-modifier-groups-title-row{
  margin-top:15px;
  margin-bottom:15px;
}

.menu-item-modifier-groups-title{
  font-weight:700;
  font-size:20px;
}

@media only screen and (max-width: 599px){
  .menu-item-modifier-groups-title{
    font-size:18px;
  }
}
