.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #E7E8FF;
  background-color:#F0F0F0;
  -webkit-transition: .4s;
  transition: .4s;
  border:1px solid #E7E8FF;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color:#8C8C8C;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #E7E8FF; 
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  background-color: #0B1246;;
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider-container{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  width:120px;
}

.slider-on-word{
  font-family:Poppins;
  font-size:16px;
  font-weight:600;
  color:#0B1246;
}

.slider-off-word{
  font-family:Poppins;
  font-size:16px;
  font-weight:400;
  color:#757575;
}
