.login-choose-type-header{
  display:flex;
  width:100%;
  align-items:center;
  justify-content:center;
  margin-top:50px;
}

.login-choose-type-header svg{
  width:500px;
  max-width:80vw;
}

.login-choose-type-subheader{
  font-size:22px;
  font-weight:500;
  color:var(--captain-black);
  width:100%;
  text-align:center;
  margin-top:20px;
  margin-bottom:40px;
}

@media only screen and (max-width: 599px){
  .login-choose-type-subheader{
    font-size:18px;
  }
}

.login-choose-type-box{
  box-sizing:border-box;
  width:100%;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  color:#262525;
  background: #FCFCFC;
  padding:24px;
  min-height:128px;
  box-shadow: 0px 4px 45px rgba(11, 18, 70, 0.05), 0px 0.500862px 5.6347px rgba(11, 18, 70, 0.025);
  border-radius: 4px;
}


.login-choose-type-box svg{
  height:32px;
  margin-right:10px;
}


@media only screen and (max-width: 599px){
  .login-choose-type-box svg{
    height:26px;
  }
}

.login-choose-type-box-top-row{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:flex-start;
  font-size:24px;
  font-weight:700;
  margin-bottom:15px;
}

.login-choose-type-box-bottom-row{
  font-size:16px;
  font-weight:400;
}


@media only screen and (max-width: 599px){
  .login-choose-type-box-top-row{
    font-size:20px;
  }

  .login-choose-type-box-bottom-row{
    font-size:14px;
  }
}
